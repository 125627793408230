import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { characterBuildType, inforType } from '../../types/characterType'
import { utils } from '../../utils/utils'

interface newCharacterType {
  name?: string
  day?: number
  month?: number
  year?: number
  date? : string
  gender?: string
  character?: characterBuildType
}
interface characterType {
  newCharacter: newCharacterType
  infor: inforType
}

const initialState: characterType = {
  newCharacter: {
    name: '',
    day: 1,
    month: 1,
    date: utils.getCurrentYear() + '-01-01',
    year: utils.getCurrentYear(),
    gender: 'male',
    character: {
      chosen: false,
      eye: 'brown',
      glass: '0',
      hairColor: 'black',
      hairStyle: '1',
      skin: 'yellow',
    },
  },
  infor: {
    image: '',
    description: ''
  }
}
const characterSlice = createSlice({
  name: 'characterSlice',
  initialState,
  reducers: {
    handleSaveData: (state, action: PayloadAction<newCharacterType>) => {
      state.newCharacter = action.payload
    },
    handleSaveInfor: (state, action: PayloadAction<inforType>) => {
      state.infor = action.payload
    }
  }
})

export const { handleSaveData, handleSaveInfor } = characterSlice.actions
export default characterSlice.reducer
