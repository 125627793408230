import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { routesObj } from '../../../routes/routes'
import logo from '../../../assets/images/logo/logo.svg'
import './index.scss'
import { bookDetailPath } from '../../../constants/book';

const GiftLayout = (props: any) => {
  const nonOnlyMobile = useMediaQuery({
    query: "(min-width: 390px)"
  });
  const layoutStyle = () => {
    let classCustom = ''
    let pathname = window.location.pathname
    if (nonOnlyMobile && pathname.includes(bookDetailPath.path) && !pathname.includes('make_character')) {
      classCustom = 'main-content book-details'
    }
    if (!nonOnlyMobile && pathname.includes(bookDetailPath.path) && !pathname.includes('make_character')) {
      classCustom = 'main-content book-details main-layout__min'
    }
    if (!nonOnlyMobile && (!pathname.includes(bookDetailPath.path) || pathname.includes('make_character'))) {
      classCustom = 'main-content main-layout__min'
    }
    if (nonOnlyMobile && (!pathname.includes(bookDetailPath.path) || pathname.includes('make_character'))) {
      classCustom = 'main-content'
    }
    return classCustom
  }
  const headerLayoutStyle = () => {
    let classCustom = ''
    if (nonOnlyMobile) {
      classCustom = 'header-default'
    } else {
      classCustom = 'header-default header-min'
    }
    return classCustom
  }
  const handleClickLogo = () => {
    window.location.href = `${routesObj.home.path}`
  }
  return (
    <div className='page-full'>
      <div className={headerLayoutStyle()}>
        <div className="header-layout" style={{ height: '64px', width: '100%', textAlign: 'center' }}>
          <div className="header-center" style={{ width: '100%', textAlign: 'center' }}>
            <div className="logo" onClick={handleClickLogo}>
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <div className={layoutStyle()}>
        {props.children}
      </div>
      <div className="footer-default">
        <div className="footer-layout">
          <div className="footer-bottom" style={{ marginBottom: '16px' }}>©JibunEHON</div>
        </div>
      </div>
    </div>
  );
}

export default GiftLayout;
