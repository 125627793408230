import { ApiServices } from './ApiServices'

export const AuthServices = {
  async loginUser(data: any) {
    return await ApiServices({}, false).post('login', data)
  },
  async logoutUser(data: any) {
    return await ApiServices({}, false).delete(`logout?authenticity_token=${data.authenticity_token}`)
  },
  async createCharacter(data: any) {
    return await ApiServices({}, false).post('characters/new', data)
  },
  async updateInfo(data: any) {
    return await ApiServices({}, false).patch('update', data)
  },
  async registrationsUser(data: any) {
    return await ApiServices({}, false).post('register', data)
  },
  async checkValidate(data: any) {
    return await ApiServices({}, false).post('check_validate', data)
  },
  async newPasswordUser(data: any) {
    return await ApiServices({}, false).post('users/password', data)
  },
  async setPassword(data: any) {
    return await ApiServices({}, false).patch('users/password', data)
  },
  async updateOrderItem(data: any) {
    return await ApiServices({}, false).patch('cart/update_order_item', data)
  },
  async getListOrderItems() {
    return await ApiServices({}, false).get('cart/list_order_items')
  },
  async checkPaymentMethodWithCard(data: any) {
    return await ApiServices({}, false).post('cart/payment_method_with_card', data)
  },
  async checkPaymentMethodWithStore(data: any) {
    return await ApiServices({}, false).post('cart/payment_method_with_store', data)
  },
  async getOrdersHistory() {
    return await ApiServices({}, false).get('list_orders_history')
  },
  async sendTestEmail(data: any) {
    return await ApiServices({}, false).post('gifts/test_email', data)
  },
  async sendContact(data: any) {
    return await ApiServices({}, false).post('static_pages/contact_send', data)
  },
  async createPreviewBook(bookId, data?: any) {
    return await ApiServices({}, false).post(`books/${bookId}/create_preview_images`, data)
  },
  async previewBook(bookId, previewId, data?: any) {
    return await ApiServices({}, false).get(`books/${bookId}/preview_images/${previewId}`, data)
  },
}
