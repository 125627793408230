import React from "react"
import { Route } from 'react-router-dom';

function AppRoute (props: any) {
  const {
    component: Component,
    layout: Layout,
    ...rest
  } = props

  const getLayout = (props: any) => {
    if (Layout) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    } else {
      return <Component {...props} />
    }
  }

  return <Route {...rest} render={(props: any) => getLayout(props)} />
}

export default AppRoute;
