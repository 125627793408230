import { Col, Row } from "antd";
import React from "react";
import "./index.scss";

interface Props {
  children?: React.ReactNode;
  index?: string;
  content?: string;
  className?: string;
  unordered?: boolean;
}

const Article: React.FC<Props> = ({
  children,
  index,
  content,
  className = "",
  unordered = false,
}) => {
  const articleIndex = () => {
    if (unordered) {
      return <Col className="article__content-index">・</Col>;
    }
    if (index) {
      return <Col className="article__content-index">{index}.</Col>;
    }
    return null;
  };

  return (
    <div className={`article__content-item ${className}`}>
      <Row wrap={false} justify="space-between">
        {articleIndex()}
        <Col className="article__content-content">{content}</Col>
      </Row>
      {children && (
        <div
          className="article__content-children"
          style={{
            maxWidth: "330px",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
export default Article;
