import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import ButtonDefault from '../../../../components/ButtonDefault'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { handleNext, handlePre } from '../../../../redux/slices/stepSlice'
import { AuthServices } from '../../../../services/AuthServices'
import { utils } from '../../../../utils/utils'
import './index.scss'

const ContactConfirmation = () => {
  const dataPage = useAppSelector((state) => state.pageData);
  const contact = useAppSelector((state) => state.contact)
  const dispatch = useAppDispatch();

  const handleNextStep = async () => {
    const params = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      name: contact?.newContact?.name,
      furigana: contact?.newContact?.furigana || '未記入',
      phone: contact?.newContact?.phone || '未記入',
      email: contact?.newContact?.email,
      order_number: contact?.newContact?.orderNumber || '未記入',
      slip_number: contact?.newContact?.slipNumber || '未記入',
      content: contact?.newContact?.content,
    };
    try {
      const res = await AuthServices.sendContact(params);
      if (res.status == 204) {
        dispatch(handleNext());
      } else {
        console.log('bbb');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePreStep = () => {
    dispatch(handlePre())
  }

  useEffect(() => {
    utils.actionScrollTo()
    window.history.pushState(null, null, window.location.pathname + window.location.search);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);

    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    dispatch(handlePre())
  }

  return (
    <>
      <Breadcrumb backButton={true} step={true} handleStep={handlePreStep}>お問い合わせ</Breadcrumb>
      <div className='contact_confirmation'>
        <div className='contact_label'>名前</div>
        <div className='contact_content'>{contact.newContact.name}</div>
        <div className='contact_label'>ふりがな</div>
        <div className='contact_content'>{contact.newContact.furigana || '未記入'}</div>
        <div className='contact_label'>電話番号</div>
        <div className='contact_content'>{contact.newContact.phone || '未記入'}</div>
        <div className='contact_label'>メールアドレス</div>
        <div className='contact_content'>{contact.newContact.email}</div>
        <div className='contact_label'>注文番号</div>
        <div className='contact_content'>{contact.newContact.orderNumber || '未記入'}</div>
        <div className='contact_label'>伝票番号</div>
        <div className='contact_content'>{contact.newContact.slipNumber || '未記入'}</div>
        <div className='contact_label'>お問い合わせ内容</div>
        <div className='contact_content' style={{ lineHeight: '22px' }}>{contact.newContact.content}</div>
        <div style={{ marginTop: '40px' }}>
          <ButtonDefault
            classButton="full-big"
            outline={false}
            iconLeft={true}
            onClick={handleNextStep}
          > 送信 </ButtonDefault>
        </div>
      </div>
    </>
  )
}

export default ContactConfirmation;
