import React from 'react';
import { useAppSelector } from '../../../hooks';
import NewContact from './NewContact';
import ContactConfirmation from './ContactConfirmation';
import ContactSend from './ContactSend';

const Contact = () => {
  const step = useAppSelector((state) => state.step)
  const page = step.page
  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return <NewContact />;
      case 1:
        return <ContactConfirmation />;
      case 2:
        return <ContactSend />;
      default:
        return <NewContact />;
    }
  }

  return (
    <>
      {conditionalComponent()}
    </>
  )
}

export default Contact;
