import React, { useEffect } from "react";
import caution from '../../assets/images/logo/caution.svg'
import "./index.scss";
import { Row, Col } from "antd";


const ExpiredGift = () => {
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.href = '/errors/expired_gift';
  }

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  return (
    <>
      <div className="gift-expired">
        <Row>
          <Col span={24}>
            <img src={caution} alt="image-expired" />
            <div className="gift-expired__description">このリンクは有効期限が
              <p>切れているか、</p>
              <p>または使用されています。</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ExpiredGift;
