import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Input } from "antd";
import Breadcrumb from "../../Breadcrumb";
import ButtonDefault from "../../ButtonDefault";
import navigateNext from "../../../assets/images/navigate_next/navigate_next_black_24dp.svg";
import { CaretDownOutlined } from "@ant-design/icons";
import { handlePre, handleNext } from "../../../redux/slices/stepSlice";
import { useAppDispatch } from "../../../hooks";
import "./index.scss";
import { giftAddressDescription } from "../../../constants/gift";

const AddressGift = () => {
  const dispatch = useAppDispatch();
  const handlePreStep = () => {
    dispatch(handlePre());
  };
  const handleNextStep = () => {
    dispatch(handleNext());
  };
  return (
    <>
      <div className="delivery-breadcrumb">
        <Breadcrumb step={true} backButton={true} handleStep={handlePreStep}>
          配送先
        </Breadcrumb>
      </div>
      <div className="btn-preview">
        <ButtonDefault
          classButton="full-big"
          outline={false}
          iconLeft={true}
          onClick={handleNextStep}
          htmlType="button"
        >
          次に進む
          <img src={navigateNext} />
        </ButtonDefault>
      </div>
      <div className="shipping-gift gift-address__des">{giftAddressDescription}</div>
      <div>
        <Form scrollToFirstError className="form-delivery address-gift">
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item label="名前" name="name">
                <Input disabled={true} />
              </Form.Item>
              <Form.Item label="ふりがな" name="furigana">
                <Input disabled={true} />
              </Form.Item>

              <Form.Item label="郵便番号" name="zipcode">
                <Input disabled={true} />
              </Form.Item>

              <Form.Item label="都道府県" name="address1">
                <Select
                  virtual={false}
                  suffixIcon={<CaretDownOutlined />}
                  disabled={true}
                  onDropdownVisibleChange={ (open) => {
                    if (open)
                      document.body.classList.add('lock-scroll')
                    else
                      document.body.classList.remove('lock-scroll')
                  } }
                ></Select>
              </Form.Item>

              <Form.Item label="住所" name="address2">
                <Input disabled={true} />
              </Form.Item>

              <Form.Item label="建物名・部屋番号" name="address3">
                <Input disabled={true} />
              </Form.Item>

              <Form.Item label="電話番号" name="phone">
                <Input disabled={true} />
              </Form.Item>
              <div className="btn-preview">
                <ButtonDefault
                  classButton="full-big"
                  outline={false}
                  iconLeft={true}
                  onClick={handleNextStep}
                  htmlType="button"
                >
                  次に進む
                  <img src={navigateNext} />
                </ButtonDefault>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default AddressGift;
