import React, { useEffect } from "react";
import Confirm from "../../../components/Order/Confirm";
import { useAppSelector } from "../../../hooks";
import { utils } from "../../../utils/utils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const OrderConfirm = () => {
  const cartOrder = useAppSelector((state) => state.cart)
  const gift = JSON.parse(utils.getStorage("items"))?.selectedBook[0]?.is_gift || cartOrder?.selectedBook[0]?.is_gift
  const dataPage = useAppSelector((state) => state.pageData);
  const pk = dataPage?.data?.data?.pk;
  const stripePromise = loadStripe(pk);
  
  useEffect(() => {
    utils.actionScrollTo()
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Confirm gift={gift} />
    </Elements>
  );
};

export default OrderConfirm;
