import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, notification } from "antd";
import ButtonDefault from "../../components/ButtonDefault";
import Breadcrumb from "../../components/Breadcrumb";
import { AuthServices } from "../../services/AuthServices";
import { useAppSelector } from "../../hooks";
import InputDefault from "../../components/InputDefault";
import SubmitError from "../../components/SubmitError";
import { routesObj } from "../../routes/routes";

const SetPassword = () => {
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [messageError, setMessageError] = useState("");
  const dataPage = useAppSelector((state) => state.pageData);
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const handleFinish = async (values: any) => {
    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      user_id: dataPage.data?.data?.user_id,
      user: {
        password: password,
      },
      items: localStorage.getItem("items"),
    };
    try {
      const res = await AuthServices.setPassword(valuesSend);
      if (res?.data?.status == 200) {
        window.location.href = routesObj.myPage.path;
      } else if (res?.data?.status == 422) {
        setIsError(true);
        setMessageError(res.data.message);
      } else if (res?.data?.status == 500) {
        window.location.href = `/errors/email_used`;
      } else {
        window.location.href = `/errors/password_token_expired`;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
  };
  const checkPassword = async () => {
    const inputEnter = form.getFieldValue("password") || "";

    if (inputEnter.length === 0) {
      return await Promise.reject("パスワードを入力してください。");
    }
    const regex = /^[A-Za-z0-9]+$/i;
    if (!regex.test(inputEnter)) {
      return await Promise.reject("英数字を入力してください。");
    }
    if (inputEnter.length < 8) {
      return await Promise.reject(
        "パスワードは8文字以上にする必要があります"
      );
    }

    return await Promise.resolve();
  };

  return (
    <>
      <Breadcrumb backButton={false}>パスワードの設定</Breadcrumb>
      <div style={{ marginBottom: "max(1px, 100vh - 565px)" }}>
        <Form
          scrollToFirstError
          form={form}
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          className="form-signup form-password"
        >
          <Row gutter={20}>
            <Col span={24}>
              {isError ? (
                <SubmitError
                  description={messageError}
                  type="error"
                  closable
                  style={{ whiteSpace: "break-spaces" }}
                />
              ) : null}
              <Form.Item
                label="パスワード"
                name="password"
                rules={[{ validator: checkPassword }]}
                className="label-form"
              >
                <InputDefault
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <ButtonDefault
                classButton="full-big"
                outline={false}
                htmlType="submit"
              >
                パスワードを設定する
              </ButtonDefault>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default SetPassword;
