import { InputProps, Input } from "antd";
// import TextArea from 'antd/lib/input/TextArea'
import React from "react";
import './index.scss'

interface Props extends InputProps {
  prefix?: string;
  defaultValue?: string;
  handleEnter?: (e) => void;
  refCurrent?: any;
}

const InputMessage: React.FC<Props> = ({
  prefix,
  defaultValue,
  handleEnter,
  refCurrent,
  ...props
}) => {
  return (
    <div className="input-message">
      <label>{prefix}</label>
      <Input.TextArea
        onKeyPress={(e) => handleEnter(e)}
        autoSize={true}
        bordered={false}
        ref={refCurrent}
        style={{
          width: '100%', padding: '6.5px 12px 6.5px 20px',
          border: 'none', fontSize: '14px',
          lineHeight: '18px', color: '#22304D'
        }}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
}

export default InputMessage;
