export const MethodOption = {
  CREDIT: {
    value: 'credit',
    label: 'credit'
  },
  STORE: {
    value: 'store',
    label: 'store'
  },

}

export const MethodStatus = [
  {
    value: "order_completed",
    label: "注文完了",
  },
  {
    value: "preparing",
    label: "準備中",
  },
  {
    value: "shipped",
    label: "発送済み",
  }
];

export const ConvenienceStore = [
  {
    value: "familymart",
    label: "ファミリーマート",
  },
  {
    value: "lawson",
    label: "ローソン",
  },
  {
    value: "ministop",
    label: "ミニストップ",
  },
  {
    value: "seicomart",
    label: "セイコーマート",
  }
];
