import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks";
import NewCharacter from "../Character/NewCharacter";
import CharacterMessage from "../Character/CharacterMessage";
import PreviewBook from "../Character/PreviewBook";
import PreviewCharacter from "../Character/PreviewCharacter";
import Mail from "./Mail";
import Address from "../../components/Order/Address";
import { handleNext } from "../../redux/slices/stepSlice";
import {addressAction} from "../../redux/slices/cartSlice";
import GiftMailThanks from "./GiftMailThanks";

const Gift = ({ data }) => {
  const step = useAppSelector((state) => state.step);
  const page = step.page;
  const gift = true
  const dispatch = useAppDispatch()
  const handleNextPage = () => {
    window.onbeforeunload = () => {};
    dispatch(handleNext());
  };

  window.onbeforeunload = () => {
    return ''
  };

  const handleAddressNext = (values: any) => {
    values.name = values?.name.replace(/\s+/g,' ').trim();
    values.furigana = values?.furigana.replace(/\s+/g,' ').trim();
    values.address2 = values?.address2.replace(/\s+/g,' ').trim();
    values.address3 = values?.address3.replace(/\s+/g, ' ').trim();
    dispatch(addressAction(values));
    dispatch(handleNext());
  };
  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return <NewCharacter data={data} />;
      case 1:
        return <CharacterMessage />;
      case 2:
        return <PreviewBook />;
      case 3:
        return <PreviewCharacter gift={gift} handleGift={handleNextPage} />;
      case 4:
        return <Address handleNextPage={handleAddressNext}/>;
      case 5:
        return <Mail />;
      case 6:
        return <GiftMailThanks />;
      default:
        return <NewCharacter data={data} />;
    }
  };

  return <>{conditionalComponent()}</>;
};

export default Gift;
