import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";
import { routesObj } from "../../../routes/routes";
import iconLogin from "../../../assets/images/user_info/img_login.svg";
import iconUser from "../../../assets/images/user_info/img_user.svg";
import iconContent from "../../../assets/images/user_info/content.svg";
import ButtonDefault from "../../../components/ButtonDefault";
import Breadcrumb from "../../../components/Breadcrumb";
import { useAppSelector } from "../../../hooks";
import { AuthServices } from "../../../services/AuthServices";
import { utils } from "../../../utils/utils";
import OrderHistoryItem from "../../../components/OrderHistoryItem";
import {notification} from "antd";

const MyPage = (data) => {
  const dataPage = useAppSelector((state) => state.pageData);
  const [listOrdersHistory, setListOrdersHistory] = useState([]);
  const currentUser = dataPage?.data?.data?.current_user || JSON.parse(utils.getStorage('current_user')) || {};
  useEffect(() => {
    notification.destroy();
    if (utils.getStorage('is_delete_gift') === 'true') {
      utils.deleteStorage('is_delete_gift')
      notification.open({
        message: "絵本と電子ギフト券は 同時に購入でないためギフトを除外します。",
        duration: 3.5
      });
    }
  }, []);

  const handleLogout = async () => {
    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
    };
    try {
      await AuthServices.logoutUser(valuesSend);
      utils.setStorage("current_user", 'null');
      utils.deleteStorage("items");
      window.location.href = `${routesObj.login.path}`;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getOrderHistory = async () => {
      try {
        const res = await AuthServices.getOrdersHistory();
        if (res.data.status == 200) {
          setListOrdersHistory(res.data.list_orders_history);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOrderHistory();
  }, []);

  const renderOrdersHistory = () => {
    return useMemo(() => {
      if (!listOrdersHistory.length) {
        return (
          <div className="order_history_empty">
            <div className="order_history_content">
              <img src={iconContent} alt="iconContent" />
              <p>注文履歴がありません</p>
            </div>
          </div>
        );
      }
      return <OrderHistoryItem orders={listOrdersHistory} />;
    }, [listOrdersHistory]);
  };

  return (
    <>
      <div className="breadcrumb_my_page">
        <Breadcrumb backButton={false}>マイページ</Breadcrumb>
      </div>
      <div className="my_page">
        <h2 className="my_page_title">{currentUser.name}さん</h2>
        <div className="my_page_icon">
          <div className="icon_border">
            <div className="icon_user">
              <img
                src={iconUser}
                alt="iconUser"
                onClick={() => {
                  window.location.href = `${routesObj.userInfo.path}`;
                }}
              />
              <p>ユーザー情報</p>
            </div>
          </div>
          <div className="icon_border" style={{ marginLeft: "18px" }}>
            <div className="icon_login">
              <img
                src={iconLogin}
                alt="iconLogin"
                onClick={() => {
                  window.location.href = `${routesObj.loginInfo.path}`;
                }}
              />
              <p>ログイン情報</p>
            </div>
          </div>
        </div>
        <h3 className="order_history_title">注文履歴</h3>
        <div className="order_history">{renderOrdersHistory()}</div>
        <div className="button_logout">
          <ButtonDefault
            classButton="full-big__w3"
            outline={true}
            onClick={handleLogout}
            htmlType="button"
          >
            ログアウト
          </ButtonDefault>
        </div>
      </div>
    </>
  );
};

export default MyPage;
