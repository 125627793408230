import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Popover } from "antd";
import { useAppSelector } from '../../hooks';
import Breadcrumb from '../../components/Breadcrumb';
import ButtonDefault from '../../components/ButtonDefault';
import giftUsage1 from '../../assets/images/gift-usage/jibunehon_giftcode_01.png'
import giftUsage2 from '../../assets/images/gift-usage/character-make-hair@2x.png'
import giftUsage3 from '../../assets/images/gift-usage/character-message-1@2x.png'
import giftUsage4 from '../../assets/images/gift-usage/character-confirmation@2x.png'
import giftUsage5 from '../../assets/images/gift-usage/jibunehon_giftcode_07.png'
import giftUsage6 from '../../assets/images/gift-usage/jibunehon_giftcode_11.png'
import { utils } from "../../utils/utils";
import { shippingFee, designNon } from "../../../configuration";
import { MethodOption } from "../../constants/methodOption";
import { routesObj } from "../../routes/routes";

import './index.scss'

const GiftUsage = () => {
  const dataPage = useAppSelector((state) => state.pageData);
  const itemsCart = useAppSelector((state) => state.cart);
  const [choosenBookId, setChoosenBookId] = useState(1);
  const [hovered, setHovered] = useState<boolean>(false);
  const [loadingGift, setLoadingGift] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const formatter = new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
  });
  const handleGiftOrder = async () => {
    setDisabled(true);
    if (JSON.parse(utils.getStorage("items"))?.selectedBook?.length > 0) {
      utils.setStorage('items', JSON.stringify(itemsCart))
    }
    if (
      JSON.parse(utils.getStorage("items"))?.selectedBook?.length > 0 &&
      itemsCart.selectedBook?.length > 0 &&
      !JSON.parse(utils.getStorage("items"))?.selectedBook[0]?.is_gift &&
      !itemsCart?.selectedBook[0]?.is_gift
    ) {
      setHovered(true);
    } else {
      // setHovered(false);
      setLoadingGift(true);
      const gift_order_info = {
        book_id: choosenBookId,
        amount: 1,
        is_gift: true,
        delivery_fee: shippingFee.price,
      };
      const formData = new FormData();
      formData.append(
        "authenticity_token",
        dataPage.data?.data?.form_authenticity_token
      );
      formData.append("item", JSON.stringify(gift_order_info));
      let response;
      try {
        response = await axios({
          method: "post",
          url: "/cart/add_gift",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
      } catch (error) {
        console.log(error);
      }
      const itemGift = {
        selectedBook: [
          {
            id: response?.data?.order_item_id || 0,
            book_id: choosenBook().id,
            image: choosenBook().images[0],
            book_name: choosenBook().name,
            price: choosenBook().price,
            amount: 1,
            is_gift: true,
            delivery_fee: shippingFee.price
          },
        ],
        bookItems: [
          {
            index: 0,
            id: response?.data?.order_item_id || 0,
            book_id: choosenBook().id,
            image: choosenBook().images[0],
            book_name: choosenBook().name,
            price: choosenBook().price,
            amount: 1,
            is_gift: true,
            giftPackaging: {
              chooseGift: true,
              pricePackaging: designNon.price,
            },
          },
        ],
        cartTotalPrice: choosenBook().price,
        cartTotalItem: 1,
        orderAddress: {},
        orderMethod: {
          type: `${MethodOption.CREDIT.value}`,
        },
      };
      if (JSON.parse(utils.getStorage("items"))?.selectedBook) {
        let itemPreGift = JSON.parse(utils.getStorage("items"));
        itemPreGift?.selectedBook?.unshift({
          id: response?.data?.order_item_id,
          book_id: choosenBook().id,
          image: choosenBook().images[0],
          book_name: choosenBook().name,
          price: choosenBook().price,
          amount: 1,
          is_gift: true,
          delivery_fee: shippingFee.price
        });
        itemPreGift?.bookItems?.unshift({
          index: itemsCart?.selectedBook.length,
          id: response?.data?.order_item_id,
          book_id: choosenBook().id,
          image: choosenBook().images[0],
          book_name: choosenBook().name,
          price: choosenBook().price,
          amount: 1,
          is_gift: true,
          giftPackaging: {
            chooseGift: true,
            pricePackaging: designNon.price,
          },
        });
        itemPreGift.cartTotalPrice =
          itemPreGift?.cartTotalPrice + choosenBook().price;
        itemPreGift.cartTotalItem = itemPreGift?.cartTotalItem + 1;

        utils.setStorage("items", JSON.stringify(itemPreGift));
      } else {
        utils.setStorage("items", JSON.stringify(itemGift));
      }
      window.location.href = `${routesObj.cart.path}`;
    }
    setDisabled(false);
    setLoadingGift(false);
  };
  const choosenBook = () => {
    return dataPage.data?.data?.books[choosenBookId - 1];
  };

  useEffect(() => {
    if (document.referrer.endsWith("/books/2")) {
      setChoosenBookId(2);
    }
  }, []);

  return (
    <>
      <Breadcrumb backButton={false}>電子ギフト券</Breadcrumb>
      <div className='gift_usage'>
        <div className='gift_usage-content'>JibunEHONを、お子さんの居る大切な方へプレゼントできる電子ギフト券です。</div>
        <div className='gift_usage-title' style={{ paddingTop: '36px', paddingBottom: '12px' }}>贈る絵本を選ぶ</div>
        <div className='gift_usage-content' style={{ paddingTop: '20px', paddingBottom: '40px' }}>
          <Select
            className='book-select'
            onSelect={(value) => { setChoosenBookId(value) }}
            value={choosenBookId}>
            {dataPage.data?.data?.books.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
          <div className="price_button box-add__gift">
            <p>
              {formatter.format(choosenBook()?.price || 0)} <span>(消費税､送料込)</span>
            </p>
            <Popover
              content="絵本と電子ギフト券は 同時に購入できません。"
              open={hovered}
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              className="full-small"
              onOpenChange={() => { if (hovered) setHovered(false) }}
              trigger="click"
            >
              <ButtonDefault
                classButton="full-small"
                outline={false}
                onClick={handleGiftOrder}
                disabled={disabled}
                htmlType="button"
                loading={loadingGift}
              >
                カートに入れる
              </ButtonDefault>
            </Popover>
          </div>
        </div>
        <div className='gift_usage-title'>電子ギフト券の使い方</div>
        <div className='gift_usage-subtitle' style={{ paddingTop: '28px', paddingBottom: '12px' }}>ギフト券をご購入された方</div>
        <div className='gift_usage-content'>ギフト券を購入するとメールでギフト用サイトのURLが送られてきます。<br />
          絵本を贈りたい相手にこのURLを送信してください。
        </div>
        <div className='gift_usage-subtitle' style={{ paddingTop: '40px', paddingBottom: '8px' }}>URLを受け取った方</div>
        <div className='gift_usage-content'>送られてきたURLをクリックしてください。</div>
        <div className='gift_usage-content' style={{ marginTop: '20px' }}>
          キャラクターを作る画面になるので名前、生年月日、性別、見た目を決めてください。
        </div>
        <img src={giftUsage1} alt='giftUsage1' height={'277px'} />
        <img src={giftUsage2} alt='giftUsage2' height={'277px'} />
        <div className='gift_usage-content' style={{ marginTop: '28px' }}>
          キャラクターが完成したら写真※を選択し、メッセージを入力して「絵本をプレビュー」をタップして絵本を確認してください。<br />
          ※絵本によっては設定内容が変わります。
        </div>
        <img src={giftUsage3} alt='giftUsage3' height={'412px'} />
        <div className='gift_usage-content' style={{ marginTop: '28px' }}>
          問題なければ次に進んで名前や生年月日に間違いないか確認してください。
        </div>
        <img src={giftUsage4} alt='giftUsage4' height={'412px'} />
        <div className='gift_usage-content' style={{ marginTop: '28px' }}>
          間違いなければ住所を入力してください。
        </div>
        <img src={giftUsage5} alt='giftUsage5' height={'392px'} />
        <div className='gift_usage-content' style={{ marginTop: '28px' }}>
          テスト送信を行い、メール受信を確認の上完了ボタンをタップしてください。
        </div>
        <img src={giftUsage6} alt='giftUsage6' height={'180px'} />
      </div>
    </>
  )
}

export default GiftUsage;
