import React, {useEffect} from 'react';
import './index.scss'
import Breadcrumb from "../../../components/Breadcrumb";
import { utils } from '../../../utils/utils';

const ActOnSpecifiedCommercialTransactions = () => {
  useEffect(() => {
    utils.actionScrollTo()
  }, [])
  return (
    <>
      <Breadcrumb backButton={false}>特定商取引法に基づく表示</Breadcrumb>
      <div className="commercial-transactions">
        <div className="commercial-transactions__content">
          <span>販売業者</span>
          <br/>
          <span>有限会社秀栄社</span>
        </div>
        <div className="commercial-transactions__content">
          <span>住所：〒870-0901</span>
          <br/>
          <span>大分県大分市西新地2丁目3番34号</span>
        </div>
        <div className="commercial-transactions__content">
          <span>運営責任者：後藤　文利</span>
        </div>
        <div className="commercial-transactions__content">
          <span>お問い合せ先：info@syueisya-oita.jpにメール、もしくはお問い合わせフォームよりご連絡お願いします</span>
        </div>
        <div className="commercial-transactions__content">
          <span>電話番号：097-551-8780（月〜金：9時〜17時 *祝日・連休・年末年始除く）</span>
        </div>
        <div className="commercial-transactions__content">
          <span>販売価格：各商品ページをご参照ください</span>
        </div>
        <div className="commercial-transactions__content">
          <span>商品代金以外の必要料金：表示された販売価格のみ。送料全国一律無料（1冊の場合）</span>
        </div>
        <div className="commercial-transactions__content">
          <span>お支払い方法：各種クレジットカードまたはコンビニ払い（ファミリーマート、ローソン、ミニストップー、セイコーマート）がご利用いただけます。</span>
        </div>
        <div className="commercial-transactions__content">
          <span>支払時期：クレジットカード決済は、商品注文時にお支払いが確定します。コンビニ決済は、注文後5日以内にお支払いください。</span>
        </div>
        <div className="commercial-transactions__content">
          <span>引渡時期：お支払い確定後、8営業日以内にお届けいたします。</span>
        </div>
        <div className="commercial-transactions__content">
          <span>サイトに記載の発送予定日はあくまで目安となります。年末年始や繁忙期などの状況により引き渡し時期が前後する場合がございます。</span>
          <br/>
          <span>実際の発送状況はマイページの購入履歴の詳細画面よりご確認お願いします。</span>
        </div>
        <div className="commercial-transactions__content">
          <span>商品の返品・交換について：オーダーメイド商品のため、お客さま都合の返品・交換はできません。 万一、商品に不備（落丁、乱丁、印刷ミス、破損、汚れ等）があった場合は、恐れ入りますが発送から14日以内に お問い合わせホームにて【ご注文番号】を記載のうえ、ご連絡ください。新しいものと交換させていただきます。</span>
        </div>
      </div>
    </>
  )
}

export default ActOnSpecifiedCommercialTransactions;
