import React from "react";
import { useAppSelector } from "../../../hooks";
import Method from "../../../components/Order/Method";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const OrderMethod = () => {
  const dataPage = useAppSelector((state) => state.pageData);
  const pk = dataPage?.data?.data?.pk;
  const stripePromise = loadStripe(pk);

  return (
    <Elements stripe={stripePromise}>
      <Method />
    </Elements>
  );
};

export default OrderMethod;
