import { Modal } from 'antd'
import React from 'react'

export default function BaseModal (props: any) {
  const {
    visible,
    handleOk,
    handleCancel,
    content,
    title,
    cancelText,
    okText,
    isSubmit,
    ...rest
  } = props
  return (
    <Modal
      title={
        <div className="font-bold" style={{ color: "#22304D" }}>
          {title}
        </div>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={cancelText || 'Cancel'}
      okText={okText || 'Ok'}
      okButtonProps={{ disabled: !!isSubmit }}
      {...rest}
    >
      {content}
    </Modal>
  )
}
