import React from 'react';
import Footer from '../../Footer';
import Header from '../../Header';
import './index.scss'

const HomeLayout = (props: any) => {
  return (
    <div className='home'>
      <Header />
      <div className="main-content-home">
        {props.children}
      </div>
      <Footer />
    </div>
  );
}

export default HomeLayout;
