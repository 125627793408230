import React, { useState } from 'react';
import { useAppSelector } from '../../hooks';
import NewCharacter from './NewCharacter';
import CharacterMessage from './CharacterMessage';
import PreviewBook from './PreviewBook';
import PreviewCharacter from './PreviewCharacter';

const Character = ({ data, pageCurrent }) => {
  const step = useAppSelector((state) => state.step)
  const page = step.page

  window.onbeforeunload = () => {
    return ''
  };

  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return <NewCharacter data={data} />;
      case 1:
        return <CharacterMessage />;
      case 2:
        return <PreviewBook />;
      case 3:
        return <PreviewCharacter />;
      default:
        return <NewCharacter data={data} />;
    }
  }

  return (
    <>
      {conditionalComponent()}
    </>
  )

}

export default Character;
