import React, { useEffect, useState, useRef } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import ButtonDefault from '../../../components/ButtonDefault'
import InputMessage from "../../../components/InputMessage";
import './index.scss'
import iconEyes from '../../../assets/images/photo/remove_red_eye.png'
import { Button, Upload, Form, Image as ImageUpload } from 'antd'
import type { InputRef } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { handleNext, handlePre } from '../../../redux/slices/stepSlice'
import { RcFile } from 'antd/lib/upload'
import SubmitError from '../../../components/SubmitError'
import { handleSaveInfor } from '../../../redux/slices/characterSlice'
import ImgCrop from '../../../utils/antd-img-crop/index';
// import ImgCrop from 'antd-img-crop';
import { utils } from '../../../utils/utils';

const CharacterMessage = () => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const { infor } = useAppSelector((state) => state.character)
  const inputRef2 = useRef<InputRef>(null);
  const inputRef3 = useRef<InputRef>(null);
  const inputRef4 = useRef<InputRef>(null);

  const [isError, setIsError] = useState(false)
  const [isErrorImg, setErrorIsImg] = useState('')
  const [messageError, setMessageError] = useState('')
  const [inputError, setInputError] = useState('');
  const [boxError, setBoxError] = useState('')

  const [message, setMessage] = useState(infor?.description);
  const breakMessage = message.split("\n");
  const [messageLine1, setMessageLine1] = useState(breakMessage[0])
  const [messageLine2, setMessageLine2] = useState(breakMessage[1])
  const [messageLine3, setMessageLine3] = useState(breakMessage[2])
  const [messageLine4, setMessageLine4] = useState(breakMessage[3])
  const [invalidValue, setInvalidValue] = useState([])

  const dataPage = useAppSelector((state) => state.pageData);
  const photoCover = dataPage.data?.data?.data?.book?.photo_cover;
  const bookId = dataPage.data?.data?.data?.book?.id;

  const [avatar, setAvatar] = useState({
    file: infor.image.file,
    url: infor.image.url
  });

  const handlePreStep = () => {
    const data = {
      image: avatar,
      description: message,
      validValue: inputError
    }
    dispatch(handleSaveInfor(data))
    dispatch(handlePre())
  }
  const checkRegexSymbol = () => {
    const pHiragana = "[\\u3041-\\u3096\\u309D-\\u309F]|\\uD82C\\uDC01|\\uD83C\\uDE00";
    const pKatakana = "[\\u30A1-\\u30FA\\u30FD-\\u30FF\\u31F0-\\u31FF\\u32D0-\\u32FE\\u3300-\\u3357\\uFF66-\\uFF6F\\uFF71-\\uFF9D]|\\uD82C\\uDC00";
    const pHan = "[\\u2E80-\\u2E99\\u2E9B-\\u2EF3\\u2F00-\\u2FD5\\u3005\\u3007\\u3021-\\u3029\\u3038-\\u303B\\u3400-\\u4DB5\\u4E00-\\u9FD5\\uF900-\\uFA6D\\uFA70-\\uFAD9]|[\\uD840-\\uD868\\uD86A-\\uD86C\\uD86F-\\uD872][\\uDC00-\\uDFFF]|\\uD869[\\uDC00-\\uDED6\\uDF00-\\uDFFF]|\\uD86D[\\uDC00-\\uDF34\\uDF40-\\uDFFF]|\\uD86E[\\uDC00-\\uDC1D\\uDC20-\\uDFFF]|\\uD873[\\uDC00-\\uDEA1]|\\uD87E[\\uDC00-\\uDE1D]";
    return new RegExp("^([A-Za-z0-9\\s\"\。ー、！？,. ? ! ' ]|" + pHiragana + "|" + pKatakana + "|" + pHan + ")*$");

  }
  const handleNextStep = () => {
    if ((Object.values(avatar).some(val => !val) || !message)) {
      utils.actionScrollTo()
    }
    if (!boxError && !inputError) {
      const data = {
        image: avatar,
        description: message
      }
      dispatch(handleNext())
      dispatch(handleSaveInfor(data))
      setIsError(false)
    } else {
      setIsError(true)
      setMessageError('正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。')
    }

  }

  const handleClick = () => {
    setAvatar({ file: '', url: '' })
  };

  const inputTextarea = (e, callback) => {
    const MAX_LEN = 37 * 2;
    const newValue = e.target.value.replace(/(\r\n|\n|\r)/gm, "");
    const newValueArr = [...newValue]
    let totalByte = 0
    let invalidValueShow = invalidValue

    for (let i = 0; i < newValueArr.length; i++) {
      if (!checkRegexSymbol().test(newValueArr[i])) {
        if (!invalidValueShow.includes(newValueArr[i])) {
          invalidValueShow.push(newValueArr[i])
          setInvalidValue(invalidValueShow)
        }
      }
      const code = newValue.charCodeAt(i);
      if ((code >= 0x0020 && code <= 0x1FFF) || (code >= 0xFF61 && code <= 0xFF9F)) {
        totalByte += 1;
      } else if ((code >= 0x2000 && code <= 0xFF60) || (code >= 0xFFA0)) {
        totalByte += 2;
      }
    }
    if (totalByte <= MAX_LEN) callback(newValue);
  }

  const beforeUpload = (file: RcFile, fileList: RcFile[], width, height) => {
    if (width === 0 || height === 0) {
      setErrorIsImg('JPG・PNGの画像しかアップロードできません。')
      setAvatar({ file: '', url: '' })
      return false
    }
    if (width > 4096 || height > 4096) {
      setErrorIsImg('4096px以下の画像を選択してください。')
      setAvatar({ file: '', url: '' })
      return false
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setAvatar({ file: file, url: reader.result });
    };
    return false;
  };

  const checkMessage = () => {
    for (let i = 0; i < message.length; i++) {
      if (!checkRegexSymbol().test(message[i])) {
        return false
      }
    }
    return true
  }

  const handleEnterInput1 = (e) => {
    if (e.key == 'Enter') {
      e.preventDefault()
      inputRef2?.current!.focus({
        cursor: 'end',
      });
    }
  }
  const handleEnterInput2 = (e) => {
    if (e.key == 'Enter') {
      e.preventDefault()
      inputRef3?.current!.focus({
        cursor: 'end',
      });
    }
  }
  const handleEnterInput3 = (e) => {
    if (e.key == 'Enter') {
      e.preventDefault()
      inputRef4?.current!.focus({
        cursor: 'end',
      });
    }
  }

  const handleEnterInput4 = (e) => {
    e.key === 'Enter' && e.preventDefault();
  }

  const beforeCrop = (file: RcFile) => {
    setErrorIsImg('')
    const isJpegOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpegOrPng) {
      setErrorIsImg('JPG・PNGの画像しかアップロードできません。')
      setAvatar({ file: '', url: '' })
      return isJpegOrPng
    }

    const isLt30M = file.size / 1024 / 1024 < 30;
    if (!isLt30M) {
      setErrorIsImg('30MB以下の画像を選択してください。')
      setAvatar({ file: '', url: '' })
    }

    return isLt30M;
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    handlePreStep();
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname + window.location.search);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [avatar, message]);

  useEffect(() => {
    utils.actionScrollTo()
    if (infor?.description) {
      const value = Array.from(infor?.description);
      for (let i = 0; i < value.length; i++) {
        if (!checkRegexSymbol().test(value[i])) {
          if (!invalidValue.includes(value[i])) {
            invalidValue.push(value[i])
          }
        }
      }
      if (invalidValue.length > 0 && !checkMessage()) {
        setInputError(invalidValue.toString().replace(/,/g, '') + ' は利用できません。')
      } else {
        setInputError('')
      }
    }
  }, []);

  useEffect(() => {
    setMessage(`${messageLine1}\n${messageLine2}\n${messageLine3}\n${messageLine4}`);
    if (!messageLine1 && !messageLine2 && !messageLine3 && !messageLine4) {
      setBoxError('メッセージを入力してください。')
    } else {
      setBoxError('')
    }
  }, [messageLine1, messageLine2, messageLine3, messageLine4]);

  useEffect(() => {
    let invalidShow = [...invalidValue]
    let tempArray= []
    invalidShow.forEach((item: any, index) => {
      if (message.includes(item)) {
        tempArray.push(item)
      }
    })

    if (tempArray.length > 0 && !checkMessage()) {
      setInputError(tempArray.toString().replace(/,/g, '') + ' は利用できません。')
    } else {
      setInputError('')
    }
  }, [message]);

  return (
    <>
      <Breadcrumb step={true} backButton={true} handleStep={handlePreStep}>キャラクターをつくる</Breadcrumb>
      <div className='character_message'>
        {isError && (
          <SubmitError
            description={messageError}
            type='error'
            closable
            style={{ whiteSpace: 'break-spaces' }}
          />
        )}
        <Form
          name="basic"
          form={form}
          onFinish={handleNextStep}
          autoComplete="off"
        >
          <div className='character_message-title'>写真</div>
          <p style={{ marginTop: '16px', marginBottom: '12px' }}> 絵本に表示される写真を選んでください</p>
          <div className='btn_img'>
            <Form.Item
              name="image"
              className={isErrorImg ? "upload-errors-disabled" : ""}
            >
              <ImgCrop
                zoom={true}
                aspect={bookId === 1 ? 6 / 9 : 18 / 11}
                rotate={false}
                modalOk="切り取り"
                modalCancel="キャンセル"
                modalTitle="画像編集"
                beforeCrop={beforeCrop}
              >
                <Upload
                  name='files'
                  accept='image/png, image/jpeg'
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  <ButtonDefault
                    classButton='outline-small'
                    outline
                  >
                    写真を選択
                  </ButtonDefault>
                </Upload>
              </ImgCrop>
            </Form.Item>
            {avatar.url && (
              <div className='character-message-2'>
                <div className='upload-img'>
                  <ImageUpload
                    src={avatar.url}
                    alt=''
                    height='140px'
                  />
                </div>
                <Button className='btn_delete' onClick={handleClick}> 削除 </Button>
              </div>
            )}
            <div className="character_message_error">
              {isErrorImg}
            </div>
            <p style={{ marginTop: isErrorImg || isError ? '28px' : '20px', marginBottom: '0px' }}> こちらのページに写真が表示されます</p>
          </div>
          <div className='character_message-img'>
            <img src={photoCover} alt='photo_cover' />
          </div>
          <div className='character_message-title' style={{ marginTop: '28px' }}>メッセージ</div>
          <p style={{ marginTop: '14px', marginBottom: '0px' }}>絵本の最後に挿入されるメッセージです。<br />
            感謝の気持ち、相手へのメッセージを入力しましょう。
          </p>
          <Form.Item
            name="textarea"
            className="message-text"
          >
            <div className="textarea-wrapper">
              <InputMessage handleEnter={handleEnterInput1} prefix="1" value={messageLine1} onChange={(e) => { inputTextarea(e, setMessageLine1) }} />
              <hr />
              <InputMessage handleEnter={handleEnterInput2} refCurrent={inputRef2} prefix="2" value={messageLine2} onChange={(e) => { inputTextarea(e, setMessageLine2) }} />
              <hr />
              <InputMessage handleEnter={handleEnterInput3} refCurrent={inputRef3} prefix="3" value={messageLine3} onChange={(e) => { inputTextarea(e, setMessageLine3) }} />
              <hr />
              <InputMessage handleEnter={handleEnterInput4} refCurrent={inputRef4} prefix="4" value={messageLine4} onChange={(e) => { inputTextarea(e, setMessageLine4) }} />
            </div>
          </Form.Item>
          <div className="character_message_error">
            {inputError}
            {boxError}
          </div>
          <div className='btn_preview'>
            <ButtonDefault
              classButton='full-big'
              outline={false}
              onClick={handleNextStep}
              type="primary" htmlType="submit"
            >
              <img src={iconEyes} alt='iconEyes' style={{ marginRight: '8px' }} />
              絵本をプレビュー
            </ButtonDefault>
          </div>
          {/* <div className='preview_alert'>
            <div>※環境によっては、プレビュー表示に</div>
            <div>1分以上かかる場合があります。</div>
          </div> */}
        </Form>
      </div>
    </>
  )
}

export default CharacterMessage
