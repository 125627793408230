import axios, { AxiosRequestConfig } from 'axios'


export const ApiServices = (
  config: AxiosRequestConfig = {},
  passToken = true
) => {
  const instanceAxios = axios.create({
    baseURL: '/',
    headers: { 'Content-Type': 'application/json' },
    ...config
  })
  // Add a request interceptor
  instanceAxios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config
    },
    async function (error) {
      // Do something with request error
      return await Promise.reject(error)
    }
  )

  // Add a response interceptor
  instanceAxios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {

      return await Promise.reject(error)
    }
  )
  return instanceAxios
}
