import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Drawer } from "antd";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { routes, routesObj } from "../../routes/routes";
import "../../styles/global.scss";
import AppRoute from "../../components/AppRoute";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getDataPage } from "../../redux/slices/pageDataSlice";
import { AuthServices } from "../../services/AuthServices";
import { handleAddItem } from "../../redux/slices/cartSlice";
import { utils } from "../../utils/utils";
import { bookDetailPath } from "../../constants/book";

function FullPage(data: any) {
  const dispatch = useAppDispatch();
  const step = useAppSelector((state) => state.step)
  useEffect(() => {
    dispatch(getDataPage(data));
  }, [JSON.stringify(data)]);

  const nonOnlyMobile = useMediaQuery({
    query: "(min-width: 390px)",
  });

  const layoutStyle = () => {
    let styleLayout;
    if (nonOnlyMobile) {
      styleLayout = {
        margin: "0 auto",
        maxWidth: "390px",
      };
    } else {
      styleLayout = {
        margin: "0 auto",
      };
    }

    return styleLayout;
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setTimeout(() => {
      if (document.getElementById('yotpo-testimonials-custom-tab').textContent === '')
        window['yotpo'].refreshWidgets();
    }, 500)
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useLayoutEffect(() => {
    const getData = async () => {
      try {
        const data = await AuthServices.getListOrderItems();
        if (data?.data?.current_user !== null) {
          utils.setStorage("current_user", JSON.stringify(data?.data?.current_user));
          if (utils.getStorage('items-check') !== null && JSON.parse(utils.getStorage('items-check'))?.cartTotalItem === 0) {
            utils.setStorage("items", utils.getStorage('items-check'));
            dispatch(handleAddItem(JSON.parse(utils.getStorage('items-check'))));
            utils.deleteStorage('items-check')
          } else {
            dispatch(handleAddItem(data?.data?.list_order_items));
            utils.setStorage("items", JSON.stringify(data?.data?.list_order_items));
          }
          if (window.location.pathname === routesObj.cart.path) {
            dispatch(handleAddItem(data?.data?.list_order_items));
            utils.setStorage("items", JSON.stringify(data?.data?.list_order_items));
          }
        } else {
          utils.setStorage("current_user", null);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const addTitleMetaTag = (stepPage: any, site: string) => {
    const title = document.getElementsByClassName('title-breadcrumb_show') || []
    document.querySelector(`meta[property="${site}:title"]`)?.remove()
    const titleMetaTag = document.createElement('meta');
    if (title[0]?.innerHTML) {
      document.title = `伝える想いをオリジナル絵本ギフトで JibunEHON（じぶんえほん） | ${title[0]?.innerHTML}`
      titleMetaTag.setAttribute("content", `伝える想いをオリジナル絵本ギフトで JibunEHON（じぶんえほん） | ${title[0]?.innerHTML}`);
    } else {
      titleMetaTag.setAttribute("content", '伝える想いをオリジナル絵本ギフトで JibunEHON（じぶんえほん）');
    }
    if (stepPage && title.length == 0) {
      document.title = '伝える想いをオリジナル絵本ギフトで JibunEHON（じぶんえほん）'
      titleMetaTag.setAttribute("content", '伝える想いをオリジナル絵本ギフトで JibunEHON（じぶんえほん）');
    }

    titleMetaTag.setAttribute("property", `${site}:title`);
    document.getElementsByTagName('head')[0].insertBefore(titleMetaTag, document.getElementsByTagName('meta')[0]);
  }

  useEffect(() => {
    addTitleMetaTag(step.page, 'og')
    addTitleMetaTag(step.page, 'twitter')
  }, [step.page]);

  return (
    <div className="app" style={layoutStyle()}>
      <Drawer>
        <div id='yotpo-testimonials-custom-tab'></div>
      </Drawer>
      <BrowserRouter>
        <Switch>
          {routes.map((route: any) => (
            <AppRoute
              exact={route.exact}
              path={route.path}
              component={route.component}
              key={route.path}
              layout={route.layout}
              data={data}
            />
          ))}
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to={routesObj.home.path} />;
            }}
          />
          <Route
            exact
            path={`${bookDetailPath.path}:id`}
            render={() => {
              return <Redirect to={routesObj.bookDetails.path} />;
            }}
          />
          {/* <Route component={pageNotFound} /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default FullPage;
