import {Row, Col, Card, Button, notification} from "antd";
import React, { useEffect, useState, useLayoutEffect } from "react";
import ButtonDefault from "../../../components/ButtonDefault";
import nextIconButton from "../../../assets/images/navigate_next/navigate_next.png";
import addIcon from "../../../assets/images/action-item/add.svg";
import removeIcon from "../../../assets/images/action-item/remove.svg";
import imageCartEmpty from "../../../assets/images/shopping_cart/img-cart.svg";
import "./index.scss";
import Breadcrumb from "../../../components/Breadcrumb";
import BaseModal from "../../../components/BaseModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  decreaseAction,
  increaseAction,
  removeAction,
} from "../../../redux/slices/cartSlice";
import { utils } from "../../../utils/utils";
import { handleNext } from "../../../redux/slices/stepSlice";
import { AuthServices } from "../../../services/AuthServices";
import {routesObj} from "../../../routes/routes";
import { designNon, designPackaging, giftTitle } from "../../../../configuration";

const ButtonGroup = Button.Group;
let timeout = null;

interface ICart {
  gift?: boolean
}

const Cart = (props: ICart) => {
  const { gift = false } = props
  const cartItems = useAppSelector((state) => state.cart);
  const currentUser = JSON.parse(utils.getStorage("current_user"));
  const items = cartItems.selectedBook || JSON.parse(utils.getStorage("items"))?.selectedBook;
  const [removeId, setRemoveId] = useState(null);
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (index) => {
    setRemoveId(index);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setRemoveId(null);
  };

  const onClickAdd = async (index) => {
    const id = index;
    const order_item_id = JSON.parse(utils.getStorage("items"))?.selectedBook[
      id
    ]?.id;
    const order_item_amount =
      JSON.parse(utils.getStorage("items"))?.selectedBook[id]?.amount + 1;
    if (order_item_amount < 100) {
      try {
        clearTimeout(timeout);
        timeout = setTimeout(
          () =>
            AuthServices.updateOrderItem({
              id: order_item_id,
              amount: order_item_amount,
              order_item_action: "update",
            }),
          1000
        );
      } catch (error) {
        console.log(error);
      }
    }
    dispatch(
      increaseAction({
        index: cartItems.selectedBook[id].id,
        id: cartItems.selectedBook[id].id,
        image: {
          url: cartItems.selectedBook[id].image
        },
        book_name: cartItems.selectedBook[id].book_name,
        price: cartItems.selectedBook[id].price,
        amount: 1,
        cover_image: cartItems.selectedBook[id].cover_image,
        date_of_birth: cartItems.selectedBook[id].date_of_birth,
        message: cartItems.selectedBook[id].message,
        name: cartItems.selectedBook[id].name,
        sex: cartItems.selectedBook[id].sex,
        giftPackaging: {
          chooseGift: true,
          typePackaging: designPackaging.designA.type,
          pricePackaging: designNon.price,
        },
        is_gift: cartItems.selectedBook[id].is_gift,
        indexArr: index
      })
    );
  };

  const onClickSub = async (index) => {
    const id = index;
    const order_item_id = JSON.parse(utils.getStorage("items"))?.selectedBook[
      id
    ]?.id;
    const order_item_amount =
      JSON.parse(utils.getStorage("items"))?.selectedBook[id]?.amount - 1;
    if (order_item_amount > 0) {
      try {
        clearTimeout(timeout);
        timeout = setTimeout(
          () =>
            AuthServices.updateOrderItem({
              id: order_item_id,
              amount: order_item_amount,
              order_item_action: "update",
            }),
          1000
        );
      } catch (error) {
        console.log(error);
      }
    }
    dispatch(decreaseAction({ itemsId: id, itemId: cartItems.selectedBook[id].id}));
  };

  const onClickRemove = async () => {
    setIsModalOpen(false);
    dispatch(removeAction(removeId));
    setRemoveId(null);
    try {
      const order_item_id = JSON.parse(utils.getStorage("items"))?.selectedBook[
        removeId
      ]?.id;
      await AuthServices.updateOrderItem({
        id: order_item_id,
        order_item_action: "delete",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickBeforeAddOrder = () => {
    if (currentUser !== null) {
      dispatch(handleNext())
    } else {
      utils.setStorage("redirect_to_card", true);
      window.location.href = `${routesObj.login.path}`
    }
  }

  useLayoutEffect(() => {
    utils.setStorage("items", JSON.stringify(cartItems));
    if (cartItems?.cartTotalItem === 0) {
      utils.setStorage('items-check', JSON.stringify(cartItems))
    }
  }, [JSON.stringify(cartItems)]);
  useEffect(() => {
    notification.destroy();
    if (utils.getStorage('is_delete_gift') === 'true') {
      utils.deleteStorage('is_delete_gift')
      notification.open({
        message: "絵本と電子ギフト券は 同時に購入でないためギフトを除外します。",
        duration: 3.5
      });
    }
  }, []);

  return (
    <>
      {cartItems.cartTotalItem !== undefined &&
      cartItems.cartTotalItem !== 0 ? (
        <>
          <Breadcrumb backButton={false}>カート</Breadcrumb>
          <div className="cart-content">
            <Row gutter={20}>
              <Col span={24}>
                <div className="btn-cart-details">
                  <ButtonDefault
                    classButton="full-big"
                    outline={false}
                    iconLeft={true}
                    onClick={handleClickBeforeAddOrder}
                    htmlType="button"
                  >
                    次に進む
                    <img src={nextIconButton} />
                  </ButtonDefault>
                </div>
                <div className="cart-items">
                  <div className="total-money">
                    <div className="title">
                      小計
                      <span
                        style={{
                          fontFamily: "Roboto_Bold",
                          fontWeight: "700",
                          margin: "0 10px",
                        }}
                      >
                        :
                      </span>
                      <span className="price price-total">
                        {utils.formatMoneyNonDecimal(
                          cartItems.cartTotalPrice,
                          0
                        )}
                      </span>
                    </div>
                  </div>
                  {cartItems.selectedBook.map((item, index) =>
                    cartItems?.selectedBook?.length !== 0 ? (
                      <>
                        <Card className="items" key={index}>
                          <Row gutter={20}>
                            <Col span={12}>
                              <div className="image-item">
                                <img src={item?.image?.url}></img>
                              </div>
                            </Col>
                            <Col span={12} className="col-name">
                              <div className="name-item">{gift ? giftTitle : (
                                <>
                                  <div>{item?.book_name}</div>
                                  <div>（{item?.name}）</div>
                                </>
                              )}</div>
                            </Col>
                          </Row>
                          <div className="price price-item">
                            ¥{utils.formatMoneyNonDecimal(item?.price, 0)}
                          </div>
                          <Row className="row-action" gutter={20}>
                            <Col span={12}>
                              <div className="btn-remove">
                                <Button onClick={(e) => showModal(index)}>
                                  <span> 削除 </span>
                                </Button>
                              </div>
                            </Col>
                            <Col span={12} className="col-action-item">
                              <div className="action-item">
                                <Row gutter={20}>
                                  <Col span={8}>
                                    <div className="title">数量</div>
                                  </Col>
                                  <Col span={16}>
                                    <ButtonGroup className="action">
                                      <Button
                                        className="btn btn-first btn-action"
                                        style={
                                          item?.amount === 1
                                            ? { background: "#EDEDED" }
                                            : null
                                        }
                                        onClick={(e) => onClickSub(index)}
                                      >
                                        <img src={removeIcon} />
                                      </Button>
                                      <Button className="btn btn-mount">
                                        <span>{item?.amount}</span>
                                      </Button>
                                      <Button
                                        className="btn btn-last btn-action"
                                        style={
                                          item?.amount === 99
                                            ? { background: "#EDEDED" }
                                            : null
                                        }
                                        onClick={(e) => onClickAdd(index)}
                                      >
                                        <img src={addIcon} />
                                      </Button>
                                    </ButtonGroup>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </>
                    ) : null
                  )}
                  <BaseModal
                    title={`${items[removeId]?.book_name}を削除`}
                    visible={isModalOpen}
                    mask={false}
                    onOk={onClickRemove}
                    onCancel={handleCancel}
                    okText=" 削除 "
                    cancelText="キャンセル"
                    closable={false}
                    content={<div>カートから削除しますか。</div>}
                  />
                </div>
                <div className="btn-cart-details">
                  <ButtonDefault
                    classButton="full-big"
                    outline={false}
                    iconLeft={true}
                    onClick={handleClickBeforeAddOrder}
                    htmlType="button"
                  >
                    次に進む
                    <img src={nextIconButton} />
                  </ButtonDefault>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div className="breadcrumb-cart__empty" style={{ margin: "0" }}>
            <Breadcrumb backButton={false}>カート</Breadcrumb>
          </div>
          <div
            className="cart-empty"
            style={{ marginBottom: "max(1px, 100vh - 609px)" }}
          >
            <div className="image">
              <img className="img-cart" src={imageCartEmpty} alt="cart-empty" />
            </div>
            <div className="title-cart__empty">カートは空です</div>
            <div className="button-cart__empty">
              <ButtonDefault
                classButton="full-medium"
                outline={false}
                onClick={() => { window.location.href = `/` }}
                htmlType="button"
              >
                トップページに戻る
              </ButtonDefault>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
