import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  page: 0
}

const checkGiftCode = async () => {
  const pathArr = window.location.pathname.split('/');
  const giftId = pathArr[pathArr.length - 1];
  const giftCode = (new URL(window.location)).searchParams.get('gift_code');
  try {
    const response = await axios({
      method: "post",
      url: "/gifts/check",
      data: { gift_id: giftId, gift_code: giftCode }
    });
    if (response.data.status == 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

const stepSlice = createSlice({
  name: 'stepSlice',
  initialState,
  reducers: {
    handleNext: (state, action: PayloadAction<number>) => {
      if (window.location.href.search('gifts') > 0 && state.page < 5) {
        checkGiftCode().then((res) => {
          if (!res) {
            window.onbeforeunload = () => {};
            window.location.href = '/errors/expired_gift'
          }
        })
      }
      state.page = state.page + 1
    },
    handlePre: (state, action: PayloadAction<number>) => {
      state.page = state.page - 1
    },
  }
})

export const { handleNext, handlePre } = stepSlice.actions
export default stepSlice.reducer
