import Breadcrumb from '../../../components/Breadcrumb'
import React, { useState, useEffect } from "react"
import Carousel from 'react-material-ui-carousel'
import ReactHtmlParser from 'react-html-parser'
import { Grid } from '@material-ui/core'
import iconNext from '../../../assets/images/arrow-book-detail/arrow-right.svg'
import iconPrev from '../../../assets/images/arrow-book-detail/arrow-left.svg'
import navigateNext from '../../../assets/images/navigate_next/navigate_next_black_24dp.svg'
import './index.scss'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import ButtonDefault from '../../../components/ButtonDefault'
import { handleNext, handlePre } from '../../../redux/slices/stepSlice'
import { utils } from '../../../utils/utils'
import { Progress, Spin } from 'antd'
import { AuthServices } from '../../../services/AuthServices'
import axios from 'axios'

const PreviewBook = () => {
  const dispatch = useAppDispatch()
  const [loadedPreviewBook, setLoadedPreviewBook] = useState<boolean>(false)
  const [previewBookData, setPreviewBookData] = useState([])
  const [bookLoadingProgress, setBookLoadingProgress] = useState(0)
  const dataPage = useAppSelector((state) => state.pageData);
  const characterData = useAppSelector((state) => state.character);
  const bookId = dataPage.data?.data?.data?.book?.id;
  let intervalCall: any = null;

  const handlePreStep = () => {
    dispatch(handlePre())
  }
  const handleNextStep = () => {
    utils.actionScrollTo()
    dispatch(handleNext())
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    dispatch(handlePre())
  }

  const getPreviewBook = async () => {
    const params = {
      authenticity_token: dataPage.data?.data?.data?.form_authenticity_token,
      new_character: characterData?.newCharacter,
      info: characterData?.infor
    };
    try {
      const res = await AuthServices.createPreviewBook(bookId, params);
      if (res.data.status == 200 && res.data.book_images.length > 0) {
        // const previewId = res.data.job_id;
        setPreviewBookData(prev => {
          return res.data.book_images
        });
        setBookLoadingProgress(90)
        setTimeout(() => {
          setLoadedPreviewBook(true)
        }, 500);
        // setInterval(async () => {
        //   try {
        //     const res = await AuthServices.previewBook(bookId, previewId);
        //     if (res.data.status == 200 && res.data.book_images.length > 0) {
        //       setPreviewBookData(res.data.book_images);
        //       setBookLoadingProgress(90)
        //       setTimeout(() => {
        //         setLoadedPreviewBook(true)
        //       }, 500);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
        // }, 5000)

        // setPreviewBookData(res.data.book_images);
        // setTimeout(() => {
        //   setLoadedPreviewBook(true)
        // }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPreviewBook();
    clearInterval(intervalCall);
    intervalCall = setInterval(() => {
      setBookLoadingProgress(prev => prev < 90 ? prev + 10 : prev);
    }, 2000);
    window.history.pushState(null, null, window.location.pathname + window.location.search);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      clearInterval(intervalCall);
    };
  }, []);

  return (
    <>
      <Breadcrumb handleStep={handlePreStep} step={true}>
        絵本をプレビュー
      </Breadcrumb>
      <div className="book_preview" style={{ maxWidth: "100%", margin: "0" }}>
        <Spin
          spinning={!loadedPreviewBook}
          indicator={
            <Progress
              className="preview-loading-bar"
              percent={bookLoadingProgress}
              status="active"
              showInfo={false}
            />
          }
        >
          <Carousel
            className="book_slider"
            autoPlay={false}
            swipe={false}
            animation="fade"
            indicators={true}
            cycleNavigation={true}
            navButtonsAlwaysVisible
            indicatorContainerProps={{
              style: {
                width: "auto",
                marginTop: "12px",
              },
            }}
            indicatorIconButtonProps={{
              style: {
                width: "12px",
                height: "12px",
                border: "1px solid black",
                color: "white",
                margin: "0 8px",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                width: "12px",
                height: "12px",
                border: "1px solid black",
                color: "#22304D",
                backgroundColor: "#22304D",
              },
            }}
            navButtonsProps={{
              style: {
                display: loadedPreviewBook ? "inline-flex" : "none",
                backgroundColor: "#fff",
                borderRadius: "6px",
                border: "1px solid",
                width: "42px",
                height: "42px",
                opacity: ".9",
                marginTop: "-10px",
              },
            }}
            navButtonsWrapperProps={{ style: { bottom: "0", top: "unset" } }}
            NextIcon={<img src={iconNext} alt="icon-next" />}
            PrevIcon={<img src={iconPrev} alt="icon-prev" />}
          >
            {loadedPreviewBook && previewBookData?.map((images, index) => {
              return (
                <>
                  <Grid item key={index}>
                    <div className="image_slider image_preview">
                      {
                        images.map((image) => {
                          if (image.startsWith('html:'))
                            return (ReactHtmlParser(image.slice(5)))
                          else if (image.endsWith('avatar'))
                            return (<img className={`preview-avatar-${bookId}`} src={`${image}`} />)
                          else
                            return (<img className='preview-image' src={`${image}`} />)
                        })
                      }
                    </div>
                  </Grid>
                </>
              );
            })}
          </Carousel>
        </Spin>
        <div className="btn-preview">
          <ButtonDefault
            classButton="full-big"
            outline={false}
            iconLeft={true}
            onClick={handleNextStep}
            htmlType="button"
          >
            次に進む
            <img src={navigateNext} />
          </ButtonDefault>
        </div>
      </div>
    </>
  );
};

export default PreviewBook;
