import Breadcrumb from "../../../components/Breadcrumb";
import React, { useState, useEffect } from "react";
import iconCart from "../../../assets/images/shopping_cart/shopping_cart_white_24dp.svg";
import "./index.scss";
import ButtonDefault from "../../../components/ButtonDefault";
import Button from "../../../components/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { handleNext, handlePre } from "../../../redux/slices/stepSlice";
import { routesObj } from "../../../routes/routes";
import { utils } from "../../../utils/utils";
import axios from "axios";
import { MethodOption } from "../../../constants/methodOption";
import Modal from "react-bootstrap/Modal";
import closeIcon from "../../../assets/images/icon-close/icon-close.svg";
import caution from "../../../assets/images/caution/caution.svg";
import { designNon, shippingFee } from "../../../../configuration";
import navigateNext from "../../../assets/images/navigate_next/navigate_next_black_24dp.svg";

interface IPreviewCharacter {
  gift?: boolean
  handleGift?: () => void
}

const PreviewCharacter = (props: IPreviewCharacter) => {
  const { gift = false, handleGift } = props
  const dispatch = useAppDispatch();
  const character = useAppSelector((state) => state.character);
  const dataPage = useAppSelector((state) => state.pageData);
  const cartOrder = useAppSelector((state) => state.cart);
  const imagesData = dataPage.data?.data?.data?.character_images || [];
  const isGift = dataPage.data?.data?.data?.is_gift || false;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePreStep = () => {
    dispatch(handlePre());
  };
  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const handleNextStep = () => {
    utils.actionScrollTo()
    dispatch(handleNext());
  };
  const handleAddToCart = async () => {
    window.onbeforeunload = () => {};
    setDisableConfirmBtn(true);
    setLoading(true)
    const order_info = {
      book_id: dataPage.data?.data?.data?.book.id,
      name: character?.newCharacter?.name,
      amount: 1,
      date_of_birth: character?.newCharacter?.date,
      sex: character?.newCharacter?.gender,
      message: character?.infor?.description,
      delivery_fee: shippingFee.price,
      character: character?.newCharacter?.character
    };
    const formData = new FormData();
    formData.append("selectedFile", character.infor.image.file);
    formData.append(
      "authenticity_token",
      dataPage.data?.data?.data?.form_authenticity_token
    );
    formData.append("item", JSON.stringify(order_info));
    let response;
    try {
      response = await axios({
        method: "post",
        url: "/cart/add_order",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response?.data?.is_delete_gift && response?.data?.status == 200) {
        utils.setStorage('is_delete_gift', true);
      } else {
        utils.deleteStorage('is_delete_gift')
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    const items = {
      selectedBook: [
        {
          id: response?.data?.order_item_id || 0,
          book_id: dataPage.data?.data?.data?.book.id,
          image: {
            url: dataPage.data?.data?.data?.book.images[0]
          },
          cover_image: response?.data?.key || "",
          name: character?.newCharacter?.name,
          book_name: dataPage.data?.data?.data?.book.name,
          price: dataPage.data?.data?.data?.book.price,
          amount: 1,
          is_gift: false,
          date_of_birth: character?.newCharacter?.date,
          sex: character?.newCharacter?.gender,
          message: character?.infor?.description,
          delivery_fee: shippingFee.price,
          character: character?.newCharacter?.character
        },
      ],
      bookItems: [
        {
          index: 0,
          id: response?.data?.order_item_id || 0,
          book_id: dataPage.data?.data?.data?.book.id,
          image: dataPage.data?.data?.data?.book.images[0],
          cover_image: response?.data?.key || "",
          name: character?.newCharacter?.name,
          book_name: dataPage.data?.data?.data?.book.name,
          price: dataPage.data?.data?.data?.book.price,
          amount: 1,
          date_of_birth: character?.newCharacter?.date,
          sex: character?.newCharacter?.gender,
          message: character?.infor?.description,
          giftPackaging: {
            chooseGift: true,
            pricePackaging: designNon.price,
          },
        },
      ],
      cartTotalPrice: dataPage.data?.data?.data?.book.price,
      cartTotalItem: 1,
      orderAddress: {},
      orderMethod: {
        type: `${MethodOption.CREDIT.value}`,
      },
    };
    if (JSON.parse(utils.getStorage("items"))?.selectedBook) {
      let itemPre = JSON.parse(utils.getStorage("items"));
      itemPre?.selectedBook?.unshift({
        id: response?.data?.order_item_id || cartOrder?.selectedBook.length,
        book_id: dataPage.data?.data?.data?.book.id,
        image: {
          url: dataPage.data?.data?.data?.book.images[0]
        },
        name: character?.newCharacter?.name,
        book_name: dataPage.data?.data?.data?.book.name,
        price: dataPage.data?.data?.data?.book.price,
        cover_image: response?.data?.key || "",
        amount: 1,
        is_gift: false,
        date_of_birth: character?.newCharacter?.date,
        sex: character?.newCharacter?.gender,
        message: character?.infor?.description,
        delivery_fee: shippingFee.price,
        character: character?.newCharacter?.character
      });
      itemPre?.bookItems?.unshift({
        index: cartOrder?.selectedBook.length,
        id: response?.data?.order_item_id || cartOrder?.selectedBook.length,
        book_id: dataPage.data?.data?.data?.book.id,
        image: dataPage.data?.data?.data?.book.images[0],
        name: character?.newCharacter?.name,
        book_name: dataPage.data?.data?.data?.book.name,
        price: dataPage.data?.data?.data?.book.price,
        cover_image: response?.data?.key || "",
        amount: 1,
        date_of_birth: character?.newCharacter?.date,
        sex: character?.newCharacter?.gender,
        message: character?.infor?.description,
        giftPackaging: {
          chooseGift: true,
          pricePackaging: designNon.price,
        },
      });
      itemPre.cartTotalPrice =
        itemPre?.cartTotalPrice + dataPage.data?.data?.data?.book.price;
      itemPre.cartTotalItem = itemPre?.cartTotalItem + 1;

      utils.setStorage("items", JSON.stringify(itemPre));
    } else {
      utils.setStorage("items", JSON.stringify(items));
    }
    window.location.href = `${routesObj.cart.path}`;
    setDisableConfirmBtn(false);
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    dispatch(handlePre())
  }
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname + window.location.search);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);


  return (
    <>
      <Breadcrumb step={true} handleStep={handlePreStep}>
        キャラクターを確認
      </Breadcrumb>
      <div className="preview-character">
        <div className="title-preview-name">
          なまえ
          <div className="data-preview-name">{character.newCharacter.name}</div>
        </div>

        <div className="title-preview-date">
          生年月日
          <div className="data-preview-date">
            {character.newCharacter.year +
              "/" +
              character.newCharacter.month +
              "/" +
              character.newCharacter.day}
          </div>
        </div>

        <div className="title-preview-sex">
          性別
          <div className="data-preview-sex">
            <span>{character.newCharacter.gender === 'male' ? "男の子" : "女の子"}</span>
          </div>
        </div>

        <div className="title-preview-character">
          キャラクター
          <div className="data-preview-character">
            <div className="avatar-preview" style={{ width: "350px" }}>
              <img
                src={
                  imagesData[`skin_${character.newCharacter.character["skin"]}`]
                }
                className="preview-canvas"
                style={{ zIndex: 0 }}
              />
              <img
                src={
                  imagesData[
                  `hair${character.newCharacter.character["hairStyle"]}_${character.newCharacter.character["hairColor"]}`
                  ]
                }
                className="preview-canvas"
                style={{ zIndex: 1 }}
              />
              <img
                src={
                  imagesData[`eye_${character.newCharacter.character["eye"]}`]
                }
                className="preview-canvas"
                style={{ zIndex: 2 }}
              />
              <img
                src={
                  character.newCharacter.character["glass"] === "1"
                    ? imagesData["glasses"]
                    : ""
                }
                className="preview-canvas"
                style={{ zIndex: 3 }}
              />
            </div>
          </div>
        </div>

        <div className="title-preview-img">
          写真
          {character.infor.image.url ? (
            <div className="data-preview-img">
              <img
                src={(character.infor.image.url)}
                style={{ width: "93px", height: "140px" }}
              />
            </div>
          ) : (
            <div className="data-preview-message">
              未添付
            </div>
          )}
        </div>

        <div className="title-preview-message">
          メッセージ
          <div className="data-preview-message">
            {character.infor.description}
          </div>
        </div>
        <div className="btn-preview">
          {isGift ? (
            <ButtonDefault
              classButton="full-big"
              outline={false}
              iconLeft={true}
              onClick={handleNextStep}
              htmlType="button"
            >
              次に進む
              <img src={navigateNext} />
            </ButtonDefault>
          ) : (
            <ButtonDefault
              classButton="full-big"
              outline={false}
              onClick={openModal}
              htmlType="button"
            >
              <img
                src={iconCart}
                alt="icon-book"
                style={{ margin: "0 5px 4px 0" }}
              />
              カートに入れる
            </ButtonDefault>
          )}
        </div>
      </div>
      <Modal show={isOpenModal} dialogClassName="modal-alert" backdrop="static">
        <Modal.Body>
          <div className="custom-modal-header">
            <img
              src={closeIcon}
              alt="icon-close"
              onClick={closeModal}
              width="15px"
              height="15px"
            />
          </div>
          <div className="caution">
            <img src={caution} alt="caution" />
          </div>
          <h2 className="caution-title">
            カートに入れた後はキャラクターの
            変更はできませんが、よろしいでしょうか
          </h2>
          <h3 className="caution-note">
            カートに入れてしまった場合はお手数ですが一度削除して最初からやり直してください。
          </h3>
          <div className="modal-buttons">
            <Button
              width="47%"
              height="64px"
              outline={true}
              border="1px solid #00A5D9"
              radius="6px"
              fontSize="20px"
              fontFamily="Noto_Sans_Medium"
              onClick={closeModal}
            >
              いいえ
            </Button>
            <ButtonDefault
              classButton="confirm-add__character"
              outline={false}
              loading={loading}
              htmlType="button"
              onClick={gift ? handleGift : handleAddToCart}
              disabled={disableConfirmBtn}
            >
              はい
            </ButtonDefault>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewCharacter;
