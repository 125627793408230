import React, { useEffect, useState } from "react";
import Cart from "../../../components/Order/Cart";
import { useAppSelector } from "../../../hooks";
import { utils } from "../../../utils/utils";

const OrderCart = () => {
  const cartOrder = useAppSelector((state) => state.cart);
  const [gift, setGift] = useState<boolean>(false)
  useEffect(() => {
    if (Object.keys(JSON.parse(utils.getStorage("items"))).length !==0  || Object.keys(cartOrder).length !==0) {
      setGift(cartOrder?.selectedBook[0]?.is_gift)
    }
  },[JSON.stringify(cartOrder)])
  return (
    <Cart gift={gift} />
  )
};

export default OrderCart;
