import React, { useState } from "react";
import { MethodOption } from "../../constants/methodOption";
import { useAppSelector } from "../../hooks";
import { utils } from "../../utils/utils";
import OrderAddress from "./OrderAddress";
import OrderCart from "./OrderCart";
import OrderConfirm from "./OrderConfirm";
import OrderGiftPackaging from "./OrderGiftPackaging";
import OrderMethod from "./OrderMethod";
import OrderSuccess from "./OrderSuccess";
import { routesObj } from "../../routes/routes";

const Order = () => {
  const [checkGift, setCheckGift] = useState(utils.getStorage('is_gift') === 'true')
  const step = useAppSelector((state) => state.step);
  const cartOrder = useAppSelector((state) => state.cart);
  let gift
  if (JSON.parse(utils.getStorage("items"))?.cartTotalItem !== undefined) {
    gift = JSON.parse(utils.getStorage("items"))?.selectedBook[0]?.is_gift || cartOrder?.selectedBook[0]?.is_gift
  } else {
    gift = false
  }
  const methodRender = utils.getStorage("method");
  const page = step.page;
  const conditionalComponent = () => {
    switch (page) {
      case 0:
        return <OrderCart />;
      case 1:
        return <OrderAddress gift={gift} />;
      case 2:
        return <OrderMethod />;
      case 3:
        return <OrderConfirm />;
      case 4:
        window.location.href = routesObj.thanks.path;
      default:
        return <OrderCart />;
    }
  };

  return <>{conditionalComponent()}</>;
};

export default Order;
