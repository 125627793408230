import React from 'react'
import ButtonDefault from '../../../components/ButtonDefault'

const Error_400 = () => {

  return (
    <div className='error'>
      <div className='error_title'>400</div>
      <p>ページが存在しません</p>
      <div className='btn_error'>
        <ButtonDefault
          classButton="full-small"
          outline={false}
          onClick={() => { window.location.href = `/` }}
          htmlType="button"
        >
          トップへ
        </ButtonDefault>
      </div>
    </div>
  )

}
export default Error_400
