import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { utils } from '../../utils/utils';

const currentUser = JSON.parse(utils.getStorage("current_user"));

interface newContactType {
  name?: string
  furigana?: string
  phone?: number
  email?: string
  orderNumber?: string
  slipNumber?: string
  content?: string
}
interface contactType {
  newContact: newContactType
}

const initialState: contactType = {
  newContact: {
    name: currentUser?.name || '',
    furigana: currentUser?.furigana || '',
    phone: null,
    email: currentUser?.email || '',
    orderNumber: '',
    slipNumber: '',
    content: '',
  }
}

const contactSlice = createSlice({
  name: 'contactSlices',
  initialState,
  reducers: {
    handleSaveContact: (state, action: PayloadAction<newContactType>) => {
      state.newContact = action.payload
    }
  }
})

export const { handleSaveContact } = contactSlice.actions
export default contactSlice.reducer
