import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { bookDetailPath } from '../../../constants/book';
import Footer from '../../Footer';
import Header from '../../Header';
import './index.scss'


const DefaultLayout = (props: any) => {
  const nonOnlyMobile = useMediaQuery({
    query: "(min-width: 390px)"
  });
  const layoutStyle = () => {
    let classCustom = ''
    let pathname = window.location.pathname
    if (nonOnlyMobile && pathname.includes(bookDetailPath.path) && !pathname.includes('make_character')) {
      classCustom = 'main-content book-details'
    }
    if (!nonOnlyMobile && pathname.includes(bookDetailPath.path) && !pathname.includes('make_character')) {
      classCustom = 'main-content book-details main-layout__min'
    }
    if (!nonOnlyMobile && (!pathname.includes(bookDetailPath.path) || pathname.includes('make_character'))) {
      classCustom = 'main-content main-layout__min'
    }
    if (nonOnlyMobile && (!pathname.includes(bookDetailPath.path) || pathname.includes('make_character'))) {
      classCustom = 'main-content'
    }
    return classCustom
  }
  return (
    <div className='page-full'>
      <Header />
      <div className={layoutStyle()}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
}

export default DefaultLayout;
