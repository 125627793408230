import { Col, Row, Select, SelectProps } from "antd";
import React from "react";
import iconSelect from '../../assets/images/dot/select.svg'
import './index.scss'

interface Props extends SelectProps {
  children?: React.ReactNode;
  widthInput?: string;
  checkDate?: boolean
}

const InputSelect: React.FC<Props> = ({
  children,
  widthInput,
  checkDate = true,
  ...props
}) => {
  return (
    <div className="input-select" >
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Col style={{ width: `${widthInput}` }}>
          <Select suffixIcon={<img src={iconSelect} alt='icon-select' />}
            placement="bottomLeft"
            status={checkDate ? '' : 'error'}
            onDropdownVisibleChange={(open) => {
              if (open) {
                document.body.classList.add('lock-scroll')
              } else {
                document.body.classList.remove('lock-scroll')
              }
            }}
            onSelect={() => setTimeout(() => {
              const selectTags = document.getElementsByClassName('ant-select')
              for (let i = 0; i < selectTags.length; i++) {
                selectTags[i].classList.remove('ant-select-focused')
                selectTags[i].getElementsByTagName('input')[0].blur()
              }
            }, 100)}
            onFocus={() => document.body.classList.add('lock-scroll')}
            onBlur={() => document.body.classList.remove('lock-scroll')}
            dropdownAlign={{
              offset: [0, 4],
              overflow: {
                adjustX: 0,
                adjustY: 0,
             },
           }}
            {...props}
          />
        </Col>
        <Col style={{ width: '15px' }}><div className="label-select">{children}</div></Col>
      </Row>
    </div>


  );
}

export default InputSelect;