import React from "react"


const AutoPlayVideo = ({src}) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoPlay
            playsInline
            style="width: 100%; height: 100%;"
            >
            <source src="${src}" type="video/mp4" />
            </video>`
        }}>
      </div>
    </>
  )
}

export default AutoPlayVideo
