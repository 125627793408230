import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Breadcrumb from "../../../components/Breadcrumb";
import ButtonDefault from "../../../components/ButtonDefault";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { handleNext, handlePre } from "../../../redux/slices/stepSlice";
import axios from "axios";
import "./index.scss";
import { utils } from "../../../utils/utils";
import { giftTitle, shippingFee } from "../../../../configuration";
import { giftAddressDescription } from "../../../constants/gift";
import visaImage from "../../../assets/images/method/visa.png";
import mastercardImage from "../../../assets/images/method/mastercard.png";
import jcbImage from "../../../assets/images/method/jcb.png";
import amexImage from "../../../assets/images/method/amex.png";
import dinersImage from "../../../assets/images/method/diners.png";
import SubmitError from "../../SubmitError";
import { MethodOption } from "../../../constants/methodOption";
import martImage from "../../../assets/images/method/cs-img.png";
import { handleFinish } from "../../../redux/slices/cartSlice";
import { useStripe } from "@stripe/react-stripe-js";
import { Modal } from 'antd';
interface IConfirm {
  gift?: boolean;
}

const Confirm = (props: IConfirm) => {
  const { gift } = props;
  const dispatch = useAppDispatch();
  const cartOrder = useAppSelector((state) => state.cart);
  const dataPage = useAppSelector((state) => state.pageData);
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stripe = useStripe();
  const [message, setMessage] = useState(
    "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
  );

  const totalPriceGift = cartOrder?.bookItems.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.giftPackaging.pricePackaging,
    0
  );
  const deliveryFee = shippingFee.price * cartOrder?.bookItems.length;
  const totalPriceItems =
    totalPriceGift + cartOrder?.cartTotalPrice + deliveryFee;
  const handleBack = () => {
    dispatch(handlePre());
  };
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    dispatch(handlePre());
  };
  const brandImages = {
    visa: visaImage,
    mastercard: mastercardImage,
    jcb: jcbImage,
    amex: amexImage,
    diners: dinersImage,
  };
  const renderItems = () => {
    if (cartOrder?.selectedBook[0].is_gift) {
      return cartOrder?.bookItems;
    } else {
      return utils.compressOrderItems(cartOrder?.bookItems);
    }
  };
  const handleConfirm = async () => {
    setError(false);

    if (cartOrder?.orderMethod?.type === MethodOption.CREDIT.value) {
      await stripe.confirmCardPayment(cartOrder?.orderMethod?.clientSecret, {
        payment_method: cartOrder?.orderMethod?.paymentMethodId
      }).then(async (res) => {
        if (res.error) {
          setError(true);
          setIsModalOpen(true);
          setMessage('お客様のクレジットカードが拒否されました。お支払い情報を再度入力してください。');
        } else {
          const formData = new FormData();
          formData.append("authenticity_token", dataPage.data?.data?.form_authenticity_token);
          formData.append("is_gift", `${gift}`);
          formData.append("delivery_fee", `${deliveryFee}`);
          formData.append("order", JSON.stringify(cartOrder));
          formData.append("payment_intent_id", res?.paymentIntent?.id)
          try {
            const response = await axios({
              method: "post",
              url: "/cart/order_complete",
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
            });
            if (response.data.status == 200) {
              utils.deleteStorage("items");
              utils.setStorage("order_id", response.data.order_id);
              utils.setStorage("is_gift", `${gift}`);
              utils.setStorage("method", cartOrder.orderMethod.type);
              dispatch(handleFinish())
              dispatch(handleNext())
            } else {
              setError(true);
              if (response.data.status == 400) {
                setMessage('カートが変更されています。カートを確認してください。');
              } else {
                setMessage('お客様のクレジットカードが拒否されました。お支払い方法を再度ご確認ください。');
              }
              utils.actionScrollTo()
              console.log(response.data.message)
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
      return;
    }

    const formData = new FormData();
    formData.append("authenticity_token", dataPage.data?.data?.form_authenticity_token);
    formData.append("is_gift", `${gift}`);
    formData.append("delivery_fee", `${deliveryFee}`);
    formData.append("order", JSON.stringify(cartOrder));
    try {
      const response = await axios({
        method: "post",
        url: "/cart/order_complete",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.status == 200) {
        utils.deleteStorage("items");
        utils.setStorage("order_id", response.data.order_id);
        utils.setStorage("is_gift", `${gift}`);
        utils.setStorage("method", cartOrder.orderMethod.type);
        dispatch(handleFinish())
        dispatch(handleNext())
      } else {
        setError(true);
        if (response.data.status == 400) {
          setMessage('カートが変更されています。カートを確認してください。');
        } else {
          setMessage('お客様のクレジットカードが拒否されました。お支払い方法を再度ご確認ください。');
        }
        utils.actionScrollTo()
        console.log(response.data.message)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  return (
    <>
      <div className="breadcrumb-confirm">
        <Breadcrumb step={true} handleStep={handleBack}>
          ご注文内容を確認
        </Breadcrumb>
      </div>
      {error ? (
        <SubmitError
          description={message}
          type="error"
          closable
          style={{ whiteSpace: "break-spaces" }}
        />
      ) : null}
      <div className="order-confirm">
        <Row gutter={20}>
          <Col span={24}>
            <ButtonDefault
              classButton="full-big"
              outline={false}
              iconLeft={true}
              onClick={handleConfirm}
              htmlType="button"
            >
              注文を確定する
            </ButtonDefault>
            <div className="pre">
              <div className="pre-ele">
                <div className="pre-ele__title">小計</div>
                <span className="pre-ele__price">
                  ¥{utils.formatMoneyNonDecimal(cartOrder?.cartTotalPrice, 0)}
                </span>
              </div>
              {/* <div className="pre-ele">
                <div className="pre-ele__title">ギフト包装</div>
                <span className="pre-ele__price">
                  ¥{utils.formatMoneyNonDecimal(totalPriceGift, 0)}
                </span>
              </div> */}
              <div className="pre-ele">
                <div className="pre-ele__title">配送料</div>
                <span className="pre-ele__price">
                  {`¥${utils.formatMoneyNonDecimal(deliveryFee, 0)}`}
                </span>
              </div>
              <div className="pre-ele pre-total">
                <div className="pre-ele__title">合計金額</div>
                <span className="pre-ele__price">
                  {`¥${utils.formatMoneyNonDecimal(totalPriceItems, 0)}`}
                </span>
              </div>
            </div>
            {gift ? (
              <div className="shipping-gift gift-confirm__des">
                {giftAddressDescription}
              </div>
            ) : (
              <div className="shipping-address">
                <div className="shipping-address__title">お届け先住所</div>
                <div className="name">{cartOrder?.orderAddress?.name}</div>
                <div>{cartOrder?.orderAddress?.zipcode}</div>
                <div>{cartOrder?.orderAddress?.address1}</div>
                <div>{cartOrder?.orderAddress?.address2}</div>
                <div>{cartOrder?.orderAddress?.address3}</div>
                <div>{cartOrder?.orderAddress?.phone}</div>
              </div>
            )}
            <div className="shipping-method">
              <div className="shipping-method__title">支払い情報</div>
              {cartOrder.orderMethod.type === MethodOption.CREDIT.value ? (
                <div style={{ display: "inline-flex" }}>
                  <img
                    src={brandImages[cartOrder.orderMethod.inforCard.brandId]}
                    alt={cartOrder.orderMethod.inforCard.brandId}
                    style={{
                      marginRight: "12px",
                      width: "73px",
                      height: "45px",
                    }}
                  ></img>
                  <div>
                    {cartOrder.orderMethod.inforCard.brand}
                    <br />
                    下4桁&emsp;
                    {cartOrder.orderMethod.inforCard.numberCard}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="store-method">コンビニ決済</div>
                  <div className="status-payment__store">入金待ち</div>
                </div>
              )}
            </div>
            <div className="shipping-details">
              <span className="shipping-title">発送の詳細</span>
              {renderItems().map((item, index) => (
                <div
                  className="order-item price-only"
                  key={index}
                >
                  <Row>
                    <Col>
                      <div className="image-item">
                        <img src={item?.image?.url} alt={`image-${index}`}></img>
                      </div>
                    </Col>
                    <Col className="col-name">
                      <div className="name-item">
                        {gift ? (
                          giftTitle
                        ) : (
                          <>
                            <span>{item?.book_name}</span>
                            <br />
                            <span style={{ fontSize: "14px" }}>
                              （{item?.name}）
                            </span>
                          </>
                        )}
                      </div>
                      <div className="gift-item">ギフトケース付き</div>
                    </Col>
                  </Row>
                  <div className="bottom-row">
                    <div className="price">
                      <div className="price-origin">
                        ¥{utils.formatMoneyNonDecimal(item?.price, 0)}
                      </div>
                      {/* {!item.giftPackaging.chooseGift ? (
                        <>
                          <div className="price-origin">
                            ¥{utils.formatMoneyNonDecimal(item?.price, 0)}
                          </div>
                          <div className="price-ship">
                            ¥{item.giftPackaging.pricePackaging}
                          </div>
                        </>
                      ) : (
                        <div className="price-origin">
                          ¥{utils.formatMoneyNonDecimal(item?.price, 0)}
                        </div>
                      )} */}
                    </div>

                    <div className="amount">
                      <span>数量 ：{item?.amount}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <ButtonDefault
              className="full-big"
              outline={false}
              iconLeft={true}
              onClick={handleConfirm}
              htmlType="button"
            >
              注文を確定する
            </ButtonDefault>
          </Col>
        </Row>
        <Modal
          visible={isModalOpen}
          title="クレジットカードエラー"
          onOk={handleBack}
          cancelButtonProps={{ hidden: true }}
        >
          {message}
        </Modal>
      </div>
    </>
  );
};

export default Confirm;
