import { createSlice } from '@reduxjs/toolkit'
interface pageDataState {
  data?: any
}

const initialState: pageDataState = {
  data: {}
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    getDataPage: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { getDataPage } = authSlice.actions

export default authSlice.reducer
