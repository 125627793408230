import React from 'react'
import './index.scss'
import { Divider } from 'antd'
import AutoPlayVideo from '../AutoPlayVideo'

const Features = ({folder}) => {
  return (
    <div className='features-jibunehon'>
      <Divider plain>JibunEHONの特徴</Divider>
      <img src={`https://shueisya-prd-s3.s3.ap-northeast-1.amazonaws.com/features/${folder}/Features1.png`} alt="feature-1" />
      <div className="feature-header">お子さんが主人公</div>
      <div className="feature-content">主人公の名前と容姿をカスタマイズ</div>
      <hr className="feature-divider" />

      <img src={`https://shueisya-prd-s3.s3.ap-northeast-1.amazonaws.com/features/${folder}/Features2.png`} alt="feature-2" />
      <div className="feature-header">写真とオリジナルメッセージ</div>
      <div className="feature-content">お子さんの写真と心を込めたメッセージを挿入</div>
      <hr className="feature-divider" />

      <img src={`https://shueisya-prd-s3.s3.ap-northeast-1.amazonaws.com/features/${folder}/Features3.png`} alt="feature-3" />
      <div className="feature-header">プロのイラストレーター描きおろし</div>
      <div className="feature-content">日本の昔話を可愛くてPOPなイラストで</div>
      <hr className="feature-divider" />

      <AutoPlayVideo src={`https://shueisya-prd-s3.s3.ap-northeast-1.amazonaws.com/features/${folder}/Features4.mp4`}/>
      <div className="feature-header">高精細なRGB印刷</div>
      <div className="feature-content">子供達の目を惹きつける鮮やかな色彩</div>
      <hr className="feature-divider" />

      <img src={`https://shueisya-prd-s3.s3.ap-northeast-1.amazonaws.com/features/${folder}/Features5.png`} alt="feature-5" />
      <div className="feature-header">耐水で破れにくい紙</div>
      <div className="feature-content">合成紙ユポを使用してるので耐水で破れにくい</div>
      <div className="feature-content">有害物質を使用してないので噛んでも安全</div>
      <hr className="feature-divider" />

      <img src={`https://shueisya-prd-s3.s3.ap-northeast-1.amazonaws.com/features/${folder}/Gift-Case6.png`} alt="feature-6" />
      <div className="feature-header">専用ギフトケース</div>
      <div className="feature-content">シックで上質な専用ギフトケースでお届け</div>
    </div>
  )
}

export default Features
