import React from "react";
import backIcon from '../../assets/images/back-arrow/arrow-back.svg'
import { useRouter } from "../../hooks/useRouter";
import './index.scss'

interface Props {
  children?: React.ReactNode;
  backButton?: boolean;
  step?: boolean;
  handleStep?: () => void;
}

const Breadcrumb: React.FC<Props> = ({
  children,
  backButton = true,
  handleStep,
  step = false,
}) => {
  const router = useRouter()
  const previousPage = () => {
    window.location.href = document.referrer
  };

  return (
    <div className="breadcrumb-custom">
      <div className="breadcrumb-layout" style={backButton ? null : { justifyContent: 'center' }}>
        {
          backButton && (
            <div className="left" onClick={step ? handleStep : previousPage}>
              <img src={backIcon} alt='back' />
              <div className="back">戻る</div>
            </div>)
        }
        <div className={backButton ? `title-center title title-breadcrumb_show` : 'title title-breadcrumb_show'}>{children}</div>
      </div >
    </div>
  );
}
export default Breadcrumb;

