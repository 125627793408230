export const giftTitle = '電子ギフト券'

export const shippingFee = {
  price: 0,
}

export const designFee = {
  price: 0,
}

export const designNon = {
  price: 0,
}

export const designPackaging = {
  designA: {
    type: '1',
    title: 'デザインA'
  },
  designB: {
    type: '2',
    title: 'デザインB'
  },
}

export const giftStatuses = [
  {
    type: 'used',
    title: '利用済',
    bgColor: '#A2A2A2'
  },
  {
    type: 'available',
    title: '利用可',
    bgColor: '#F5AD23'
  },
  {
    type: 'expired',
    title: '期限切れ',
    bgColor: '#A2A2A2'
  },
]
