import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDataPage } from "../../../redux/slices/pageDataSlice";
import { Button, Form, notification, Spin } from "antd";
import { AuthServices } from "../../../services/AuthServices";
import InputDefault from "../../../components/InputDefault";
import SubmitError from "../../../components/SubmitError";
import { utils } from "../../../utils/utils";

const UserInfo = (data) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const dataPage = useAppSelector((state) => state.pageData);
  const currentUser = JSON.parse(utils.getStorage("current_user"));
  const openNotificationWithIcon = (type, message) => {
    notification.destroy();
    notification.open({
      message: "更新しました。",
      duration: 3.5,
    });
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDataPage(data));
  }, [dispatch]);

  const onFinish = async (values: any) => {
    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      name: values.name.trim(),
      furigana: values.furigana.trim(),
      update_form: "user_info",
    };
    setLoading(true);

    try {
      const res = await AuthServices.updateInfo(valuesSend);
      const name = (form.getFieldValue("name") || "").trim();
      const furigana = (form.getFieldValue("furigana") || "").trim();
      form.setFieldValue("name", name);
      form.setFieldValue("furigana", furigana);
      if (res.status == 204) {
        setLoading(false);
        setIsError(false);
        openNotificationWithIcon("success", res.data.message);
      } else {
        setIsError(true);
        setMessageError(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkName = async () => {
    const inputEnter = (form.getFieldValue("name") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("名前を入力してください。");
    }
    if (inputEnter.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }
  };

  const checkFurigana = async () => {
    const inputEnter = (form.getFieldValue("furigana") || "").trim();
    const regex = /^[\u3040-\u309F\u3000 ]*$/g;
    if (!regex.test(inputEnter || inputEnter.length === 0)) {
      return await Promise.reject("ふりがなを入力してください。");
    }
    if (inputEnter.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
  };

  return (
    <Spin spinning={loading} wrapperClassName='profile-spin'>
      <div className="breadcrumb_info">
        <Breadcrumb backButton={true}>ユーザー情報</Breadcrumb>
      </div>
      <div className="form_info user-info">
        <Form
          name="basic"
          form={form}
          initialValues={{
            name: currentUser?.name,
            furigana: currentUser?.furigana,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {isError ? (
            <SubmitError
              description={messageError}
              type="error"
              closable
              style={{ whiteSpace: "break-spaces" }}
            />
          ) : null}
          <Form.Item
            label="名前"
            name="name"
            className="label_form_update"
            rules={[{ validator: checkName }]}
          >
            <InputDefault className="input_update" />
          </Form.Item>

          <Form.Item
            label="ふりがな"
            name="furigana"
            className="label_form_update"
            rules={[{ validator: checkFurigana }]}
          >
            <InputDefault />
          </Form.Item>

          <Form.Item>
            <div className="btn_update">
              <Button type="primary" htmlType="submit">
                変更する
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default UserInfo;
