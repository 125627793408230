import React from 'react';
import './index.scss'
import { routesObj } from '../../routes/routes'
import { useMediaQuery } from 'react-responsive';
import { useRouter } from '../../hooks/useRouter'
import logo from '../../assets/images/logo/logo.svg'
import cart from '../../assets/images/shopping_cart/shopping_cart_black_24dp.svg'
import account from '../../assets/images/account_circle/account_circle_black_24dp.svg'
import { Badge } from 'antd';
import { useAppSelector } from '../../hooks';

const Header = () => {
  const cartItems = useAppSelector((state) => state.cart)
  const handleClickLogo = () => {
    window.location.href = `${routesObj.home.path}`
  }
  const handleLogin = () => {
    window.location.href = `${routesObj.myPage.path}`
  }
  const handleCart = () => {
    window.location.href = `${routesObj.cart.path}`
  }
  const count = cartItems.cartTotalItem;

  const nonOnlyMobile = useMediaQuery({
    query: "(min-width: 390px)"
  });
  const layoutStyle = () => {
    let classCustom = ''
    if (nonOnlyMobile) {
      classCustom = 'header-default'
    } else {
      classCustom = 'header-default header-min'
    }
    return classCustom
  }
  return (
    <div className={layoutStyle()}>
      <div className="header-layout" style={{ height: '64px' }}>
        <div className="header-left">
          <div className="logo" onClick={handleClickLogo}>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="header-right">
          <div className="cart" onClick={handleCart}>
            {count ? (
              <Badge count={count} overflowCount={999}>
                <img src={cart} alt="cart" />
              </Badge>
            ) : (
              <img src={cart} alt="cart" />
            )}
          </div>
          <div className="account" onClick={handleLogin}>
            <img src={account} alt="user" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

