import { Button, ButtonProps } from "antd";
import React from "react";
import './index.scss'

interface Props extends ButtonProps {
  children?: React.ReactNode;
  outline?: any;
  iconLeft?: boolean;
  classButton?: any
}

const ButtonDefault: React.FC<Props> = ({
  children,
  outline = true,
  iconLeft = false,
  classButton = null,
  ...props
}) => {
  return (
    <Button
      className={iconLeft ? `button-custom ${classButton}` : classButton}
      style={outline ? {
        backgroundColor: '#fff',
        color: '#00A5D9',
        borderColor : '#00A5D9',
      } : {
        backgroundColor: '#00A5D9',
        color: '#fff',
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export default ButtonDefault;