import React from "react";
import Address from "../../../components/Order/Address";
import AddressGift from "../../../components/Order/AddressGift";
import { useAppDispatch } from "../../../hooks";
import { addressAction } from "../../../redux/slices/cartSlice";
import { handleNext } from "../../../redux/slices/stepSlice";

interface IOrderAddress {
  gift?: boolean
}

const OrderAddress = (props: IOrderAddress) => {
  const { gift } = props
  const dispatch = useAppDispatch()
  const handleNextPage = (values: any) => {
    values.name = values?.name.replace(/\s+/g,' ').trim();
    values.furigana = values?.furigana.replace(/\s+/g,' ').trim();
    values.address2 = values?.address2.replace(/\s+/g,' ').trim();
    values.address3 = values?.address3?.replace(/\s+/g,' ').trim();
    dispatch(addressAction(values));
    dispatch(handleNext());
  };

  return (
    <>
      { gift ? (
        <AddressGift />
      ) : (
        <Address handleNextPage={handleNextPage} />
      )}
    </>

  )
}

export default OrderAddress;
