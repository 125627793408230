import React from 'react';
import logoCross from '../../../assets/images/cross/img-cross.svg'
import ButtonDefault from '../../../components/ButtonDefault';
const PasswordTokenExpired = () => {
  return (
    <>
      <div className='logo-cross'>
        <img src={logoCross}/>
      </div>
      <div className='text-again-account-cross'>
        <span>期限切れのため再度</span> <br />
        <span>リセットお願いします</span>
      </div>
      <div className='btn-accout-cross'>
        <ButtonDefault
          classButton='full-medium'
          outline={false}
          iconLeft={true}
          onClick={() => { window.location.href = `/users/sign_up` }}
          htmlType="button"
        >
          アカウントを作成
        </ButtonDefault>
      </div>
    </>
  )
}

export default PasswordTokenExpired;
