import React from 'react';
import './index.scss'
import logoCross from '../../../assets/images/cross/img-cross.svg'
import ButtonDefault from '../../../components/ButtonDefault';
const EmailAlreadyExists = () => {
  return (
    <>
      <div className='logo-cross'>
        <img src={logoCross}/>
      </div>
      <div className='text-email-cross'>
        <span>このメールアドレスは</span> <br />
        <span>すでに登録済みです</span>
      </div>
      <div className='btn-email-cross'>
        <ButtonDefault
          classButton='full-medium'
          outline={false}
          iconLeft={true}
          onClick={() => { window.location.href = `/` }}
          htmlType="button"
        >
          トップへ
        </ButtonDefault>
      </div>
    </>
  )
}

export default EmailAlreadyExists;
