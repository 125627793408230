import React, { useEffect } from "react";
import imageCartSuccess from "../../../assets/images/shopping_cart/img-check.svg";
import "./index.scss";

const GiftMailThanks = () => {
  window.onbeforeunload = () => {};
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.href = '/errors/expired_gift'
  }

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  return (
    <div className="order-success gift-mail__thanks">
      <img
        className="image-order-success"
        src={imageCartSuccess}
        alt="cart-success"
      />
      <div className="order-thanks">
        絵本のご注文が
        <p>完了いたしました</p>
      </div>
      <div className="order-des">
        ご注文いただきありがとうございます。
        <p>商品到着までもうしばらく</p>
        <p>お待ちください。</p>
      </div>
    </div>
  );
};

export default GiftMailThanks;
