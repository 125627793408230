import { Alert, AlertProps } from "antd";
import React from "react";
import './index.scss'


interface Props extends AlertProps {
  children?: React.ReactNode;
}

const SubmitError: React.FC<Props> = ({
  children,
  ...props
}) => {
  return (
    <Alert className="error-submit" {...props} />
  );
}

export default SubmitError;