import { Form, Input, InputProps } from "antd";
import React from "react";
import './index.scss'

interface Props extends InputProps {
  children?: React.ReactNode;
  placeholder?: string;
  isPassword?: boolean;
}

const InputDefault: React.FC<Props> = ({
  children,
  isPassword = false,
  ...props
}) => {
  return (
    <div className="input-custom" >
      {isPassword ?
        (<Input.Password iconRender={visible => visible} style={{ height: '52px', width: '100%' }} {...props} />) :
        (<Input style={{ height: '52px', width: '100%' }} {...props} />)}
    </div>
  );
}

export default InputDefault;
