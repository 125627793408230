import React, { useEffect, useState } from "react";
import { Form, Button, notification } from "antd";
import ButtonDefault from "../../components/ButtonDefault";
import { routesObj } from "../../routes/routes";
import InputDefault from "../../components/InputDefault";
import { AuthServices } from "../../services/AuthServices";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Breadcrumb from "../../components/Breadcrumb";
import SubmitError from "../../components/SubmitError";
import { utils } from "../../utils/utils";
import { handleAddItem } from "../../redux/slices/cartSlice";

const Login = () => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [isLogin, setIsLogin] = useState<boolean>((utils.getStorage('current_user') !== 'null' && utils.getStorage('current_user') !== null))
  const dataPage = useAppSelector((state) => state.pageData);
  const [form] = Form.useForm();
  const checkEmail = async () => {
    const inputEnter = (form.getFieldValue("email") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("メールアドレスを入力してください。");
    }
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (!regex.test(inputEnter)) {
      return await Promise.reject("メールアドレスは不正な値です。");
    }

    return await Promise.resolve();
  };
  const onFinish = async (values: any) => {
    setDisabled(true);
    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      user: {
        email: values.email.trim(),
        password: values.password,
      },
      items: localStorage.getItem("items"),
    };
    try {
      const res = await AuthServices.loginUser(valuesSend);
      if (res.data.status == 200) {
        if (res.data.is_delete_gift) {
          utils.setStorage('is_delete_gift', true);
        } else {
          utils.deleteStorage('is_delete_gift')
        }
        setIsError(false);
        utils.deleteStorage("items");
        const redirectToCard = JSON.parse(utils.getStorage('redirect_to_card'))
        if (redirectToCard == true) {
          utils.setStorage("redirect_to_card", null);
          window.location.href = `${routesObj.cart.path}`;
        } else {
          window.location.href = `${res.data.redirect}`;
        }
      } else {
        setIsError(true);
        setMessageError(res.data.message);
        setDisabled(false);
      }
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
  };

  const redirectSignUp = () => {
    window.location.href = `${routesObj.registrations.path}`;
  };

  const redirectForgotPassword = () => {
    window.location.href = `${routesObj.newPassword.path}`;
  };

  useEffect(() => {
    if (isLogin) {
      window.location.href = routesObj.home.path
    }
  },[])

  return (
    <>
      <Breadcrumb backButton={false}>ログイン</Breadcrumb>
      <div className="form-login">
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {isError ? (
            <SubmitError
              description={messageError}
              type="error"
              closable
              style={{ whiteSpace: "break-spaces" }}
            />
          ) : null}
          <Form.Item
            label="メールアドレス"
            name="email"
            className="label-form"
            rules={[{ validator: checkEmail }]}
          >
            <InputDefault />
          </Form.Item>

          <Form.Item
            className="label-form"
            label="パスワード"
            name="password"
            rules={[
              { required: true, message: "パスワードを入力してください。" }
            ]}
          >
            <InputDefault type="password" />
          </Form.Item>
          <div className="forgot-password">
            <span onClick={redirectForgotPassword}>パスワードを忘れた場合</span>
          </div>
          <Form.Item className="btn-sign-in">
            <ButtonDefault
              classButton="full-big"
              outline={false}
              htmlType="submit"
              disabled={disabled}
            >
              ログイン
            </ButtonDefault>
          </Form.Item>
          <div className="btn-sign-up">
            <ButtonDefault
              classButton="full-big__w6"
              outline
              onClick={redirectSignUp}
              htmlType="button"
            >
              アカウントを作成
            </ButtonDefault>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
