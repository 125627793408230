import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import "./index.scss";
import BaseStep from "../../../components/BaseStep";
import { Col, Row } from "antd";
import { useAppSelector } from "../../../hooks";
import { utils } from "../../../utils/utils";
import { designFee, giftStatuses } from "../../../../configuration";
import visaImage from "../../../assets/images/method/visa.png";
import mastercardImage from "../../../assets/images/method/mastercard.png";
import jcbImage from "../../../assets/images/method/jcb.png";
import amexImage from "../../../assets/images/method/amex.png";
import dinersImage from "../../../assets/images/method/diners.png";
import pmartImage from "../../../assets/images/store-image/pmart.png";
import miniImage from "../../../assets/images/store-image/mini.png";
import lawsonImage from "../../../assets/images/store-image/lawson.png";
import smartImage from "../../../assets/images/store-image/smart.png";

import {
  ConvenienceStore,
  MethodStatus,
} from "../../../constants/methodOption";
import { giftAddressDescription } from "../../../constants/gift";

const MyPageHistory = () => {
  const dataPage = useAppSelector((state) => state.pageData);
  const order = dataPage?.data?.data?.order;
  const shippingAddress = dataPage?.data?.data?.shipping_address;

  const [data, setData] = useState(null);
  const brandImages = {
    visa: visaImage,
    mastercard: mastercardImage,
    jcb: jcbImage,
    amex: amexImage,
    diners: dinersImage,
  };
  const storeImages = {
    familymart: pmartImage,
    lawson: lawsonImage,
    ministop: miniImage,
    seicomart: smartImage,
  };
  const capitalizeFirstLetter = (str: any) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const checkStatus = () => {
    return MethodStatus.findIndex(
      (item) => item.value === order?.delivery_status
    );
  };

  const convenienceStoreName = (name) => {
    return ConvenienceStore.find((item) => item.value === name)?.label || "";
  };

  const formatter = new Intl.NumberFormat("ja-JP", {
    currency: "JPY",
  });

  const handleBack = () => {
    window.location.href = `/my_page`;
  };

  const renderGiftStatus = (item: any) => {
    let giftStatus = giftStatuses.find(
      (status) => status.type === item.gift_status
    );
    return (
      <span
        className="amount-gift-status"
        style={{ backgroundColor: giftStatus.bgColor }}
      >
        {giftStatus.title}
      </span>
    );
  };

  useEffect(() => {
    setData(utils.compressOrderItemsHistory(order?.items));
  }, [JSON.stringify(order?.items)]);

  return (
    <>
      <div className="breadcrumb-confirm">
        <Breadcrumb step={true} handleStep={handleBack}>
          注文内容
        </Breadcrumb>
      </div>
      <div className="order-confirm">
        <div style={{ fontSize: "20px", fontFamily: "Noto_Sans_Regular" }}>
          注文日時：{order?.order_date}
        </div>
        <div
          style={{
            fontWeight: "700",
            fontFamily: "Noto_Sans_Bold",
            fontSize: "20px",
            marginTop: "36px",
            lineHeight: "20px",
          }}
        >
          ご請求額
        </div>
        <div className="pre" style={{ marginTop: "18px" }}>
          <div className="pre-ele">
            <div className="pre-ele__title">小計</div>
            <span className="pre-ele__price">
              ¥{formatter.format(order?.subtotal)}
            </span>
          </div>
          {/* <div className="pre-ele">
            <div className="pre-ele__title">ギフト包装</div>
            <span className="pre-ele__price">
              ¥{formatter.format(order?.price_packaging)}
            </span>
          </div> */}
          <div className="pre-ele">
            <div className="pre-ele__title">配送料</div>
            <span className="pre-ele__price">
              ¥{formatter.format(order?.delivery_fee)}
            </span>
          </div>
          <div className="pre-ele pre-total">
            <div className="pre-ele__title">合計金額</div>
            <span className="pre-ele__price">
              ¥{formatter.format(order?.order_price)}
            </span>
          </div>
        </div>
        {order?.is_gift ? (
          <div className="shipping-gift gift-address__des history-gift__des">
            {giftAddressDescription}
          </div>
        ) : (
          <div className="shipping-address" style={{ marginBottom: "20px" }}>
            <div className="shipping-address__title">お届け先住所</div>
            <div className="name">{shippingAddress?.name}</div>
            <div>{shippingAddress?.postal_code}</div>
            <div>{shippingAddress?.prefecture}</div>
            <div>{shippingAddress?.city}</div>
            <div>{shippingAddress?.town_area}</div>
            <div>{shippingAddress?.phone}</div>
          </div>
        )}
        <div className="shipping-method" style={{ marginTop: 0 }}>
          <div className="shipping-method__title">支払い情報</div>
          {order?.payment_method == "store_method" ? (
            <div>
                <>
                  <div className="store-method">コンビニ決済</div>
                  {order?.status == 'paid' ?
                    <div
                        className="status-payment__store has-payment__store"
                        style={{ backgroundColor: "#A2A2A2" }}
                      >
                        支払い済み
                    </div>
                    :
                    <div className="status-payment__store">入金待ち</div>
                  }
                </>
            </div>
          ) : (
            <div style={{ display: "inline-flex" }}>
              <img
                src={brandImages[order?.payment_info?.brand]}
                alt={order?.payment_info?.brand}
                style={{ marginRight: "12px", width: "73px", height: "45px" }}
              ></img>
              <div style={{ width: "90px" }}>
                {capitalizeFirstLetter(order?.payment_info?.brand)}
                <br />
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>下4桁</p>
                  <p>{order?.payment_info?.last4}</p>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="shipping-details">
          <div className="shipping-title shipping-title__history">
            発送の詳細
          </div>
          {data?.map((item, index) => (
            <div
              className={
                item.packaging || item.is_gift
                  ? "order-item my-page__history price-only"
                  : "order-item my-page__history"
              }
              key={index}
            >
              <Row>
                <Col className="history_img" span={12}>
                  <div className="image-item">
                    <img src={item.book_img} alt="book_img"></img>
                  </div>
                </Col>
                <Col span={12} className="col-history">
                  <div className="name-item">
                    <div>
                      {order?.is_gift ? "電子ギフト券" : item.book_name}
                    </div>
                    {order?.is_gift ? null : (
                      <span style={{ fontSize: "14px", paddingLeft: "6px" }}>
                        ({item.character_name})
                      </span>
                    )}
                  </div>
                  <div className="gift-item">ギフトケース付き</div>
                </Col>
              </Row>
              <div className="bottom-row">
                <div className="price">
                  <div className="price-origin">
                    ¥{formatter.format(item.book_price)}
                  </div>
                  {!item.packaging && (
                    <div className="price-ship">
                      ¥{formatter.format(designFee.price)}
                    </div>
                  )}
                </div>
                <div className="amount">
                  <span>数量：{item.amount}</span>
                  {item.is_gift && renderGiftStatus(item)}
                </div>
              </div>
            </div>
          ))}
        </div>
        {!order?.is_gift && order?.status === 'paid' && (
          <>
            <div
              style={{
                fontWeight: "700",
                fontFamily: "Noto_Sans_Bold",
                fontSize: "20px",
                lineHeight: "20px",
                marginBottom: "28px",
              }}
            >
              発送状況
            </div>
            <Row justify="center">
              <Col>
                <BaseStep
                  responsive={false}
                  steps={MethodStatus}
                  current={checkStatus()}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default MyPageHistory;
