import React from 'react'
import ButtonDefault from '../../../components/ButtonDefault'
import './index.scss'

const Error_500 = () => {

  return (
    <div className='error'>
      <div className='error_title'>500</div>
      <p>予期せぬエラーが発生しました</p>
      <div className='btn_error'>
        <ButtonDefault
          classButton="full-small"
          outline={false}
          onClick={() => { window.location.href = `/` }}
          htmlType="button"
        >
          トップへ
        </ButtonDefault>
      </div>
    </div>
  )

}
export default Error_500
