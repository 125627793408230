import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import pageDataReducer from '../slices/pageDataSlice'
import stepReducer from '../slices/stepSlice'
import cartReducer from '../slices/cartSlice'
import characterReducer from '../slices/characterSlice'
import contactReducer from '../slices/contactSlice'


const rootReducer = combineReducers({
  pageData: pageDataReducer,
  step: stepReducer,
  cart: cartReducer,
  character: characterReducer,
  contact: contactReducer
})

export const store = configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
