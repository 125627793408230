import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './index.scss'
import closeIcon from '../../assets/images/icon-close/icon-close.svg'
import glasses from '../../assets/images/glasses/glasses.svg'

interface Props {
  isOpenModal?: boolean
  closeModal?: () => void
  skinData?: any
  imagesData?: object
  hairStyleData: any
  hairColorData: any
  eyeData: any
  avatar: any,
  saveAvatar: any
}

const AvatarModal: React.FC<Props> = ({
  skinData = [],
  imagesData = {},
  hairStyleData = [],
  hairColorData = [],
  eyeData = [],
  isOpenModal = false,
  closeModal,
  avatar,
  saveAvatar
}) => {
  const radioCircle = (name, color, value, varName, callback) => {
    return (
      <label className="radio-circle" onClick={() => { callback(value) }}>
        <input type="radio" name={name} value={value} checked={varName === value} />
        <div className="option" style={{ background: color }} />
      </label>
    )
  }
  const radioHairStyle = (value) => {
    return (
      <label className="radio-square" onClick={() => { setHairStyle(value.name) }}>
        <input type="radio" name="hair-style" value={value.name} checked={hairStyle === value.name} />
        <div className="option" style={{ background: '#FFFFFF' }}>
          <img className="hair-style-option" src={value.preview} />
        </div>
      </label>
    )
  }
  const [skin, setSkin] = useState(avatar['skin'] || skinData[0]?.name)
  const [hairStyle, setHairStyle] = useState(avatar['hairStyle'] || hairStyleData[0])
  const [hairColor, setHairColor] = useState(avatar['hairColor'] || hairColorData[0]?.name)
  const [eye, setEye] = useState(avatar['eye'] || eyeData[0]?.name)
  const [glass, setGlass] = useState(avatar['glass'])

  const buildAndSaveAvatar = () => {
    saveAvatar({
      chosen: true,
      skin: skin,
      hairStyle: hairStyle,
      hairColor: hairColor,
      eye: eye,
      glass: glass
    })
  }

  const setValue = () => {
    setSkin(avatar['skin'] || skinData[0]?.name)
    setHairStyle(avatar['hairStyle'] || hairStyleData[0])
    setHairColor(avatar['hairColor'] || hairColorData[0]?.name)
    setEye(avatar['eye'] || eyeData[0]?.name)
    setGlass(avatar['glass'])
  }

  return (
    <Modal
      show={isOpenModal}
      onShow={setValue}
      onHide={closeModal}
      dialogClassName="modal-dialog"
      backdrop="static"
    >
      <Modal.Body>
        <div className="custom-modal-header">
          <img src={closeIcon} alt="icon-close" onClick={closeModal} />
          <div className="title">キャラクターをつくる</div>
          <button
            type="button"
            onClick={buildAndSaveAvatar}
            style={{
              width: "67px",
              height: "40px",
              fontFamily: "Noto_Sans_Bold",
              fontSize: "17px",
              fontWeight: "700",
              border: "none",
              borderRadius: "6px",
              backgroundColor: "#F5AD23",
              color: "#FFF",
              wordBreak: "keep-all"
            }}
          >
            完了
          </button>
        </div>
        <div className="avatar-preview">
          <img src={imagesData[`skin_${skin}`]} className="preview-canvas" style={{ zIndex: 0 }} />
          <img src={imagesData[`hair${hairStyle}_${hairColor}`]} className="preview-canvas" style={{ zIndex: 1 }} />
          <img src={imagesData[`eye_${eye}`]} className="preview-canvas" style={{ zIndex: 2 }} />
          <img src={imagesData['glasses']} className={glass === '1' ? 'preview-canvas' : 'hidden'} style={{ zIndex: 3 }} />
        </div>
        <Tabs
          defaultActiveKey="skin"
          fill
        >
          <Tab eventKey="skin" title="肌">
            {
              skinData.map((skinOption) => {
                return radioCircle('skin', skinOption.colorCode, skinOption.name, skin, setSkin)
              })
            }
          </Tab>
          <Tab eventKey="hair" title="髪">
            <div className="hair-style">
              {
                hairStyleData.map((hairStyleOption) => {
                  return radioHairStyle(hairStyleOption)
                })
              }
            </div>
            <div className="hair-color" style={{ paddingTop: '20px' }}>
              {
                hairColorData.map((hairColorOption) => {
                  return radioCircle('hair-color', hairColorOption.colorCode, hairColorOption.name, hairColor, setHairColor)
                })
              }
            </div>
          </Tab>
          <Tab eventKey="eye" title="目">
            {
              eyeData.map((eyeOption) => {
                return radioCircle('eye', eyeOption.colorCode, eyeOption.name, eye, setEye)
              })
            }
          </Tab>
          <Tab eventKey="glass" title="メガネ">
            <label className="radio-square" onClick={() => { setGlass('0') }}>
              <input type="radio" name="glass" value="0" checked={glass === '0'} />
              <div className="option" style={{ background: '#F0F7F8' }}>
                <span style={{ color: '#22304D', fontSize: '20px', fontFamily: 'Noto_Sans_Bold', fontWeight: '700', width: '100%', height: '100%', margin: 'auto', left: 0 }}>なし</span>
              </div>
            </label>
            <label className="radio-square" onClick={() => { setGlass('1') }}>
              <input type="radio" name="glass" value="1" checked={glass === '1'} />
              <div className="option" style={{ background: '#FFFFFF' }}>
                <img src={glasses} alt="glasses" style={{ height: '52px', paddingTop: '25px' }} />
              </div>
            </label>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default AvatarModal;
