import React, { useEffect } from 'react';
import Breadcrumb from "../../../components/Breadcrumb";
import { utils } from '../../../utils/utils';

const SeoReview = () => {
  useEffect(() => {
    utils.actionScrollTo()
  }, [])
  return (
    <div id='yotpo-testimonials-custom-tab'></div>
  );
}

export default SeoReview;
