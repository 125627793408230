import React from "react"
import './src/styles/global.scss'
import './src/styles/antdCustom.scss'
import { Provider } from 'react-redux'
import { store } from './src/redux/store'
import 'antd/dist/antd.css';


import FullPage from "./src/pages/FullPage";

function App (props: any) {
  return (
    <Provider store={store}>
      <FullPage data={props} />
    </Provider>
  );
}

export default App;
