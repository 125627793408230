import React from 'react';
import GiftPackaging from '../../../components/Order/GiftPackaging';

const OrderGiftPackaging = () => {
  return (
    <GiftPackaging />
  )
}

export default OrderGiftPackaging;
