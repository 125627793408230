import { Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import InputDefault from "../../../components/InputDefault";
import nextIconButton from "../../../assets/images//navigate_next/navigate_next.png";
import InputSelect from "../../../components/InputSelect";
import "./index.scss";
import { utils } from "../../../utils/utils";
import ButtonDefault from "../../../components/ButtonDefault";
import AvatarModal from "../../../components/AvatarModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { handleNext } from "../../../redux/slices/stepSlice";
import SubmitError from "../../../components/SubmitError";
import { handleSaveData, handleSaveInfor } from "../../../redux/slices/characterSlice";

const NewCharacter = (data) => {
  const dispatch = useAppDispatch();
  const dataPage = useAppSelector((state) => state.pageData);
  const bookId = dataPage.data?.data?.data?.book?.id || '';
  const character = useAppSelector((state) => state.character);
  const skinData = dataPage.data?.data?.data?.skin_colors || [];
  const hairStyleData = dataPage.data?.data?.data?.hair_styles || [];
  const hairColorData = dataPage.data?.data?.data?.hair_colors || [];
  const eyeData = dataPage.data?.data?.data?.eye_colors || [];
  const imagesData = dataPage.data?.data?.data?.character_images || [];
  const isGift = dataPage.data?.data?.data?.is_gift || false;
  const [avatar, setAvatar] = useState(character.newCharacter.character);
  const [isError, setIsError] = useState(false);
  const [change, setChange] = useState(false)
  const [changeGender, setChangeGender] = useState(false)
  const [messageError, setMessageError] = useState("");
  const [form] = Form.useForm();
  const [gender, setGender] = useState(character.newCharacter.gender);
  const [year, setYear] = useState(`${character.newCharacter.year}`);
  const [day, setDay] = useState(`${character.newCharacter.day}`);
  const [month, setMonth] = useState(`${character.newCharacter.month}`);
  const [inputName, setInputName] = useState(`${character.newCharacter.name}`);
  const [errorMessage, setErrorMessage] = useState('');
  const checkDate = utils.dateIsValid(year, month, day);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const renderGenderCheck = (data) => {
    if(data === 'female') {
      return 'ちゃん'
    } else {
      return 'くん'
    }
  }
  const handleFinish = () => {
    setChange(true)
    if (!inputName) {
      setErrorMessage("なまえを入力してください。")
    }
    if (
      !checkDate ||
      (!avatar.chosen && !character.newCharacter.character.chosen) ||
      !inputName
    ) {
      utils.actionScrollTo()
      setIsError(true)
      setMessageError(
        "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
      );
    }
    if (
      checkDate &&
      (avatar.chosen || character.newCharacter.character.chosen) &&
      inputName
    ) {
      const data = {
        name: inputName ? inputName : character.newCharacter.name,
        day: +day || character.newCharacter.day,
        date: year + "-" + utils.formatDayAndMonth(month) + "-" + utils.formatDayAndMonth(day) || character.newCharacter.date,
        month: +month || character.newCharacter.month,
        year: +year || character.newCharacter.year,
        gender: gender || character.newCharacter.gender,
        character: avatar || character.newCharacter.character,
      };
      const messageName = `${inputName ? inputName : character.newCharacter.name}${renderGenderCheck(gender || character.newCharacter.gender)}`
      const momotaroMessage = `${messageName}\n${messageName}、うまれてきてくれて　ありがとう。\nこの　えほんのように、やさしく　たくましく\nそだっていくのを　これからも　みまもっているね。`
      const issunboushiMessage = `${messageName}へ\nこれから　いろんなことがあるけど、　この　えほんのように\nいろんなことに　ちゃれんじ　していってね。\n`
      dispatch(handleNext());
      dispatch(handleSaveData(data));
      dispatch(handleSaveInfor({
        image: character.infor.image || '',
        description: character.infor.description || bookId === 1 ? momotaroMessage : issunboushiMessage
      }))
    }
  };
  const openModal = () => {
    setIsOpenModal(true);
  }
  const closeModal = () => {
    setIsOpenModal(false);
  }
  const saveAvatar = (avatar) => {
    setAvatar(avatar);
    setChange(true)
    setIsOpenModal(false);
  }
  const renderGender = () => {
    if (character.newCharacter.character.chosen && !changeGender) {
      return character.newCharacter.gender;
    } else {
      return gender;
    }
  }
  const checkInput =  (e) => {
    const regex = new RegExp(/^[\u3040-\u309F]*$/g);
    if (e.target.value.length === 0) {
      setErrorMessage("なまえを入力してください。");
    } else if (!regex.test(e.target.value)) {
      setErrorMessage("正しくない文字があります。");
    } else if (e.target.value.length > 7) {
      setErrorMessage("最大は7文字になります。");
    } else {
      setErrorMessage('')
      setInputName(e.target.value)
    }
  };
  const checkShowAvatar = () => {
    if (character.newCharacter.character.chosen && !change) {
      return character.newCharacter.character;
    } else {
      return avatar;
    }
  }
  const backToBook = () => {
    const bookId = dataPage.data?.data?.data?.book?.id || '';
    if (bookId !== '') {
      window.location.href = `${location.origin}/books/${bookId}`
    }
  }

  useEffect(() => {
    if (isError || !avatar.chosen) {
      utils.actionScrollTo()
    }
  }, [])
  useEffect(() => {
    utils.actionScrollTo()
    window.addEventListener('popstate', backToBook);
    return () => {
      window.removeEventListener('popstate', backToBook);
    };
  }, [])

  return (
    <>
      <Breadcrumb handleStep={backToBook} backButton={!isGift}>キャラクターをつくる</Breadcrumb>
      <div className="character">
        <div className={isError ? "box-error__show" : "box-error__hide"}>
          <SubmitError
            description={messageError}
            type="error"
            closable
            style={{ whiteSpace: "break-spaces" }}
          />
        </div>
        <Form
          scrollToFirstError
          form={form}
          layout="vertical"
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label="なまえ"
                name="nameCharacter"
                className="label-form"
              >
                <InputDefault
                  defaultValue={character.newCharacter.name}
                  placeholder="ひらがな7文字まで"
                  onChange={(e) => checkInput(e)}
                  status={errorMessage ? 'error' : null}
                />
                {errorMessage ? (
                  <div className="error-date error-invalid">{errorMessage}</div>
                ) : null}
              </Form.Item>
              <Form.Item label="生年月日" name="birth" className="label-form">
                <Row style={{ justifyContent: "space-between" }}>
                  <Col style={{ width: "130px" }}>
                    <InputSelect
                      checkDate={checkDate}
                      widthInput="105px"
                      onChange={(value) => setYear(value)}
                      options={utils.getYears()}
                      defaultValue={character.newCharacter.year}
                    >
                      年
                    </InputSelect>
                  </Col>
                  <Col style={{ width: "95px" }}>
                    <InputSelect
                      checkDate={checkDate}
                      widthInput="70px"
                      onChange={(value) => setMonth(value)}
                      options={utils.getMonths()}
                      defaultValue={character.newCharacter.month}
                    >
                      月
                    </InputSelect>
                  </Col>
                  <Col style={{ width: "95px" }}>
                    <InputSelect
                      checkDate={checkDate}
                      widthInput="70px"
                      onChange={(value) => setDay(value)}
                      options={utils.getDays()}
                      defaultValue={character.newCharacter.day}
                    >
                      日
                    </InputSelect>
                  </Col>
                </Row>
                {!checkDate ? (
                  <div className="error-date error-invalid">生年月日は存在していません。</div>
                ) : null}
              </Form.Item>
              <Form.Item label="性別" name="gender" className="label-form">
                <Row className="character-sex">
                  <Col span={12}>
                    {renderGender() === "male" ? (
                      <ButtonDefault
                        classButton="full-gender"
                        outline={false}
                        onClick={() => { }}
                        htmlType="button"
                      >
                        男の子
                      </ButtonDefault>
                    ) : (
                      <ButtonDefault
                        classButton="outline-gender"
                        outline
                          onClick={() => {
                            setGender("male")
                            setChangeGender(true)
                          }}
                        htmlType="button"
                      >
                        男の子
                      </ButtonDefault>
                    )}
                  </Col>
                  <Col span={12} className="button-right">
                    {renderGender() === "female" ? (
                      <ButtonDefault
                        classButton="full-gender"
                        outline={false}
                        onClick={() => { }}
                        htmlType="button"
                      >
                        女の子
                      </ButtonDefault>
                    ) : (
                      <ButtonDefault
                        classButton="outline-gender"
                        outline
                          onClick={() => {
                            setGender("female")
                            setChangeGender(true)
                          }}
                        htmlType="button"
                      >
                        女の子
                      </ButtonDefault>
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="キャラクター"
                className="label-form choose-feature"
                name="avatar"
              >
                <Row
                  className={
                    avatar["chosen"]
                      ? "feature-character"
                      : "feature-none feature"
                  }
                  style={!avatar.chosen && change && {border: "1px solid #F20C0C"}}
                >
                  <Col>
                    {checkShowAvatar()["chosen"] ? (
                      <div
                        className="avatar-preview"
                        style={{ width: "350px" }}
                      >
                        <img
                          src={imagesData[`skin_${checkShowAvatar()["skin"]}`]}
                          className="preview-canvas"
                          style={{ zIndex: 0, left: 0 }}
                        />
                        <img
                          src={
                            imagesData[
                              `hair${checkShowAvatar()["hairStyle"]}_${
                                checkShowAvatar()["hairColor"]
                              }`
                            ]
                          }
                          className="preview-canvas"
                          style={{ zIndex: 1, left: 0 }}
                        />
                        <img
                          src={imagesData[`eye_${checkShowAvatar()["eye"]}`]}
                          className="preview-canvas"
                          style={{ zIndex: 2, left: 0 }}
                        />
                        <img
                          src={imagesData["glasses"]}
                          className={ checkShowAvatar()["glass"] === "1" ? "preview-canvas" : "hidden" }
                          style={{ zIndex: 3, left: 0 }}
                        />
                        <div className="btn-modal__custom">
                          <ButtonDefault
                            classButton="btn-custom__modal btn-custom__modal-small"
                            outline
                            onClick={openModal}
                            htmlType="button"
                          >編集 </ButtonDefault>
                        </div>
                      </div>
                    ) : (
                      <ButtonDefault
                        outline
                        classButton="btn-custom__modal btn-custom__modal-big"
                        onClick={openModal}
                        htmlType="button"
                      >
                        キャラクターを作る
                      </ButtonDefault>
                    )}
                  </Col>
                </Row>
                {!avatar.chosen && change ? (
                  <div className="error-character error-invalid">キャラクターを作成してください。</div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          <ButtonDefault
            classButton="full-big"
            outline={false}
            iconLeft={true}
            onClick={handleFinish}
            htmlType="button"
          >
            次に進む
            <img src={nextIconButton} />
          </ButtonDefault>
        </Form>
      </div>
      <AvatarModal
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        saveAvatar={saveAvatar}
        imagesData={imagesData}
        skinData={skinData}
        hairStyleData={hairStyleData}
        hairColorData={hairColorData}
        eyeData={eyeData}
        avatar={avatar}
      />
    </>
  );
};

export default NewCharacter;
