import React, { useState, useEffect } from "react";
import Breadcrumb from "../../Breadcrumb";
import ButtonDefault from "../../ButtonDefault";
import nextIconButton from "../../../assets/images/navigate_next/navigate_next.png";
import visaImage from "../../../assets/images/method/credit-img.png";
import martImage from "../../../assets/images/method/cs-img.png";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import InputSelect from "../../InputSelect";
import { Radio, RadioChangeEvent, Row, Col, Card, Input, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { handlePre, handleNext } from "../../../redux/slices/stepSlice";
import "./index.scss";
import { utils } from "../../../utils/utils";
import { MethodOption } from "../../../constants/methodOption";
import SubmitError from "../../SubmitError";
import { methodAction } from "../../../redux/slices/cartSlice";
import { AuthServices } from "../../../services/AuthServices";
import { shippingFee } from "../../../../configuration";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";

const Method = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state) => state.cart);
  const [option, setOption] = useState(
    order?.orderMethod?.type || MethodOption.CREDIT.value
  );
  const [inforCredit, setInforCredit] = useState(
    order?.orderMethod?.inforCard?.infoCard || ""
  );
  const [error, setError] = useState(false);
  const [statusInfoCard, setStatusInfoCard] = useState("");
  const [messageErrorInfoCard, setMessageErrorInfoCard] = useState("");
  const [messageErrorCVC, setMessageErrorCVC] = useState("");
  const [messageDate, setMessageDate] = useState("");
  const [message, setMessage] = useState(
    "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
  );
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const totalPriceGift = order?.bookItems.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.giftPackaging.pricePackaging,
    0
  );
  const deliveryFee = shippingFee.price * order?.bookItems.length;
  const totalPriceItems = totalPriceGift + order?.cartTotalPrice + deliveryFee;
  const handleBack = () => {
    dispatch(handlePre());
  };
  const clearErrorMessage = () => {
    setError(false);
    setStatusInfoCard("");
    setMessageErrorInfoCard("");
    setMessageErrorCVC("");
    setMessageDate("");
  };
  const handleNextStep = async () => {
    clearErrorMessage();
    setChecked(false);
    setDisabled(true);
    if (option === MethodOption.CREDIT.value) {
      if (!stripe || !elements) {
        return;
      }

      const cardNumberElement = elements?.getElement(CardNumberElement);
      await stripe.createToken(cardNumberElement).then(async (res) => {
        if (res.error) {
          setMessage("正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。")
          setError(true);
        } else {
          const dataSend = {
            price: totalPriceItems,
            token: res?.token
          };
          const resPayment = await AuthServices.checkPaymentMethodWithCard(dataSend);
          if (resPayment.data.status == 200) {
            const dataMethod = {
              type: option || order.orderMethod.type,
              inforCard: {
                infoCard:
                  inforCredit.trim() ||
                  order?.orderMethod?.inforCard?.infoCard?.trim(),
                numberCard: resPayment?.data?.card_data?.last4,
                month: resPayment?.data?.card_data?.exp_month,
                year: resPayment?.data?.card_data?.exp_year,
                brand: resPayment?.data?.brand,
                brandId: resPayment?.data?.brand_id
              },
              paymentMethodId: resPayment?.data?.payment_method_id,
              clientSecret: resPayment?.data?.client_secret
            };
            dispatch(methodAction(dataMethod));
            dispatch(handleNext());
          } else {
            setMessage(resPayment?.data?.message);
            setError(true);
            utils.actionScrollTo()
          }
        }
      });
    } else {
      try {
        const dataSend = {
          type: option || order.orderMethod.type,
          info_card: {}
        };
        const res = await AuthServices.checkPaymentMethodWithStore(dataSend);
        if (res.data.status == 200) {
          const dataMethod = {
            type: option || order.orderMethod.type,
            inforCard: {},
            paymentMethodId: res?.data?.payment_method_id,
          };
          console.log('dataMethod', dataMethod)
          dispatch(methodAction(dataMethod));
          dispatch(handleNext());
        } else {
          setMessage(res?.data?.message);
          setError(true);
          utils.actionScrollTo()
        }
      } catch (error) {
        console.log(error);
      }
    }
    setDisabled(false);
    setChecked(true);
  };
  const onBackButtonEvent = (e) => {
    e.preventDefault()
    dispatch(handlePre())
  }
  const seletedOption = (e: RadioChangeEvent) => {
    setOption(e.target.value);
  };
  const handleChange = (event) => {
    const isCharacter = /[^a-zA-Z ]/g;
    const result = event.target.value.toUpperCase();
    if (!isCharacter.test(result)) {
      setInforCredit(result);
    } else {
      setInforCredit(result.replace(/[^a-zA-Z ]/g, ""));
    }
    let name_length = result.replace(/[^a-zA-Z ]/g, "")?.length || 0
    if (name_length == 0) {
      setStatusInfoCard("error");
      setMessageErrorInfoCard("カード情報を入力してください。");
    } else {
      setStatusInfoCard("");
      setMessageErrorInfoCard("");
    }
  };

  const confirmPayment = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const cardNumberElementOptions = {
    iconStyle: "solid",
    style: {
      base: {
        fontFamily: "Noto_Sans_Regular",
        fontSize: "20px",
        color: "#22304D",
        padding: "4px 11px",
        "::placeholder": {
          color: "#00000040",
        },
      },
      invalid: {
        color: "#000",
      },
    },
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);
  useEffect(() => {
    const TodayDate = new Date();
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const cardExpiryElement = elements?.getElement(CardExpiryElement);
    const cardCvcElement = elements?.getElement(CardCvcElement);
    cardNumberElement?.update({ placeholder: "カード番号" })
    cardExpiryElement?.update({ placeholder: "01/" + (TodayDate.getYear() - 100) });
    cardCvcElement?.update({ placeholder: "" });
  }, [elements]);

  return (
    <>
      <Breadcrumb step={true} handleStep={handleBack}>
        お支払い方法を選択
      </Breadcrumb>
      {error ? (
        <SubmitError
          description={message}
          type="error"
          closable
          style={{ whiteSpace: "break-spaces" }}
        />
      ) : null}
      <div className="order-method">
        <Row>
          <Col span={24}>
            <Card title="支払い方法" className={option === MethodOption.CREDIT.value ? 'card-option' : 'card-option card-option__other'}>
              <Radio.Group onChange={seletedOption} defaultValue={option}>
                <Row
                  justify="space-between"
                  className="option first-option"
                  defaultValue={option}
                >
                  <Radio value={MethodOption.CREDIT.value}>
                    クレジットカード決済
                  </Radio>
                  <img className="image-card" src={visaImage} alt="visa" style={{width: '182px', height: '22px'}}/>
                </Row>
                {option === MethodOption.CREDIT.value && (
                  <div className="card-infor">
                    <div className="credit-infor">
                      <div className="title-card">
                        クレジットカード情報を入力
                      </div>
                      <Input
                        placeholder="クレジットカード名義人"
                        className="top"
                        defaultValue={inforCredit}
                        value={inforCredit}
                        onChange={handleChange}
                        status={statusInfoCard}
                      />
                      <CardNumberElement className="ant-input top stripe-input" options={cardNumberElementOptions} />
                      <div className="message-error">
                        {messageErrorInfoCard}
                      </div>
                    </div>
                    <div className="date-expiry">
                      <div className="title-card">有効期限</div>
                      <CardExpiryElement className="ant-input top stripe-input" options={cardNumberElementOptions} />
                      <div className="message-error">{messageDate}</div>
                    </div>
                    <div className="credit-cvc">
                      <div className="title-card">セキュリティコード</div>
                      <CardCvcElement className="ant-input top stripe-input" options={cardNumberElementOptions} />
                      <div className="message-card">カード裏面の数字3桁または4桁</div>
                      <div className="message-error">{messageErrorCVC}</div>
                    </div>
                  </div>
                )}
                <Row justify="space-between" className="option option-store">
                  <Radio  value={MethodOption.STORE.value} onClick={clearErrorMessage}>コンビニ決済</Radio>
                  <img
                    className="image-card card-last"
                    src={martImage}
                    alt="mart"
                  />
                </Row>
                {option === MethodOption.STORE.value && (
                  <div className="card-infor__store">
                    <div className="content">
                      商品の発送は代金のお支払い後になります。 お支払いの際に必要な支払いコードと確認コードは注文確定後にメールでお知らせいたします。
                    </div>
                  </div>
                )}
              </Radio.Group>
            </Card>
            <div className={disabled ? 'confirm-checkbox-disable' : 'confirm-checkbox'}>
            <Checkbox onChange={confirmPayment} disabled={disabled}>
              <a href="/static_pages/terms_of_use" target="_blank" style={{color: "#00A5D9"}}> 利用規約</a>に同意します
            </Checkbox>
            </div>
            <ButtonDefault
              classButton="full-big next-button"
              outline={false}
              iconLeft={true}
              onClick={handleNextStep}
              htmlType="button"
              disabled={!checked}
            >
              次に進む
              <img src={nextIconButton} />
            </ButtonDefault>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Method;
