import React from "react";
import './index.scss'

interface Props {
  children?: React.ReactNode;
  height?: string;
  onClick?: () => void;
  radius?: string;
  width?: string;
  disabled?: boolean;
  fontSize?: string;
  border?: string;
  outline?: any;
  fontFamily?: string;
  iconLeft?: boolean;
  fontWeight?: string;
}

const Button: React.FC<Props> = ({
  children,
  height,
  onClick,
  radius,
  border,
  disabled,
  width,
  fontSize,
  fontFamily,
  outline = true,
  iconLeft = false,
  fontWeight,
  ...props
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={iconLeft ? "button-custom" : null}
      style={outline ? {
        fontFamily,
        border,
        fontWeight,
        backgroundColor: '#fff',
        color: '#00A5D9',
        borderRadius: radius,
        height,
        fontSize,
        width,
        wordBreak: 'keep-all'
      } : {
        fontFamily,
        fontWeight: '700',
        border: 'none',
        backgroundColor: '#00A5D9',
        color: '#fff',
        borderRadius: radius,
        height,
        fontSize,
        width,
        wordBreak: 'keep-all'
      }}
      {...props}
    >
      {children}
    </button >
  );
}

export default Button;
