import DefaultLayout from '../components/Layouts/DefaultLayout'
import GiftLayout from '../components/Layouts/GiftLayout'
import HomeLayout from '../components/Layouts/HomeLayout'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Details from '../pages/Books/Details'
import Character from '../pages/Character'
import MyPage from '../pages/Profile/MyPage'
import UserInfo from '../pages/Profile/UserInfo'
import LoginInfo from '../pages/Profile/LoginInfo'
import GiftUsage from '../pages/GiftUsage'
import NewPassword from '../pages/NewPassword'
import SetPassword from '../pages/SetPassword'
import Registrations from '../pages/Registrations'
import Password from '../pages/Password'
import Order from '../pages/Order'
import TermsOfUse from "../pages/StaticPage/TermsOfUse"
import PrivacyPolicy from "../pages/StaticPage/PrivacyPolicy"
import ActOnSpecifiedCommercialTransactions from "../pages/StaticPage/ActOnSpecifiedCommercialTransactions"
import Contact from "../pages/StaticPage/Contact"
import SeoReview from "../pages/StaticPage/SeoReview"
import Error_500 from "../pages/Errors/Error_500"
import Error_400 from "../pages/Errors/Error_400"
import EmailAlreadyExists from "../pages/Errors/EmailAlreadyExists"
import RegisterYourAccountAgain from "../pages/Errors/RegisterYourAccountAgain"
import PasswordTokenExpired from "../pages/Errors/PasswordTokenExpired"
import Gift from '../pages/Gift'
import MyPageHistory from "../pages/Profile/MyPageHistory"
import ExpiredGift from '../pages/ExpiredGift'
import { bookDetailPath } from '../constants/book'
import OrderSuccess from '../pages/Order/OrderSuccess'

HomeLayout
interface IRoute {
  title: string
  exact?: boolean
  path: string
  layout: any
  component: any
}
interface IRoutes {
  home: IRoute,
  login: IRoute,
  bookDetails: IRoute,
  character: IRoute
  myPage: IRoute,
  userInfo: IRoute,
  loginInfo: IRoute,
  cart: IRoute,
  giftUsage: IRoute,
  newPassword: IRoute,
  password: IRoute,
  setPassword: IRoute,
  registrations: IRoute,
  termsOfUse: IRoute,
  privacyPolicy: IRoute,
  actOnSpecifiedCommercialTransactions: IRoute,
  contact: IRoute,
  seoReview: IRoute,
  error_500: IRoute,
  error_400_expired: IRoute,
  error_400: IRoute
  emailAlreadyExists: IRoute,
  registerYourAccountAgain: IRoute,
  passwordTokenExpired: IRoute,
  gift: IRoute,
  mypageHistory: IRoute,
  thanks: IRoute
}

export const routesObj: IRoutes = {
  home: {
    exact: true,
    title: 'Home',
    path: '/',
    layout: HomeLayout,
    component: Home,
  },
  login: {
    exact: true,
    title: 'Login',
    path: '/users/sign_in',
    layout: DefaultLayout,
    component: Login,
  },
  bookDetails: {
    exact: true,
    title: 'Books Details',
    path: `${bookDetailPath.path}:id`,
    layout: DefaultLayout,
    component: Details,
  },
  character: {
    exact: true,
    title: 'Characters',
    path: '/books/:id/make_character',
    layout: DefaultLayout,
    component: Character,
  },
  myPage: {
    exact: true,
    title: 'My Page',
    path: '/my_page',
    layout: DefaultLayout,
    component: MyPage,
  },
  userInfo: {
    exact: true,
    title: 'User Info',
    path: '/user_info',
    layout: DefaultLayout,
    component: UserInfo,
  },
  loginInfo: {
    exact: true,
    title: 'User Info',
    path: '/login_info',
    layout: DefaultLayout,
    component: LoginInfo,
  },
  cart: {
    exact: true,
    title: 'Cart',
    path: '/cart/list_orders',
    layout: DefaultLayout,
    component: Order,
  },
  giftUsage: {
    exact: true,
    title: 'Gift-Usage',
    path: '/static_pages/gift_usage',
    layout: DefaultLayout,
    component: GiftUsage,
  },
  newPassword: {
    exact: true,
    title: 'Reset password',
    path: '/users/password/new',
    layout: DefaultLayout,
    component: NewPassword,
  },
  setPassword: {
    exact: true,
    title: 'Set password',
    path: '/users/password',
    layout: DefaultLayout,
    component: SetPassword,
  },
  registrations: {
    exact: true,
    title: 'Registrations',
    path: '/users/sign_up',
    layout: DefaultLayout,
    component: Registrations,
  },
  password: {
    exact: true,
    title: 'Password',
    path: '/password',
    layout: DefaultLayout,
    component: Password,
  },
  termsOfUse: {
    exact: true,
    title: 'Terms-Of-Use',
    path: '/static_pages/terms_of_use',
    layout: DefaultLayout,
    component: TermsOfUse,
  },
  privacyPolicy: {
    exact: true,
    title: 'Privacy-Policy',
    path: '/static_pages/privacy_policy',
    layout: DefaultLayout,
    component: PrivacyPolicy,
  },
  actOnSpecifiedCommercialTransactions: {
    exact: true,
    title: 'Act-On-Specified-Commercial-Transactions',
    path: '/static_pages/act_on_specified_commercial_transactions',
    layout: DefaultLayout,
    component: ActOnSpecifiedCommercialTransactions,
  },
  contact: {
    exact: true,
    title: 'Contact',
    path: '/static_pages/contact',
    layout: DefaultLayout,
    component: Contact,
  },
  seoReview: {
    exact: true,
    title: 'SeoReview',
    path: '/static_pages/seo_review',
    layout: DefaultLayout,
    component: SeoReview,
  },
  error_500: {
    exact: true,
    title: 'Error',
    path: '/errors/error',
    layout: DefaultLayout,
    component: Error_500,
  },
  error_400: {
    exact: true,
    title: 'Not_Found',
    path: '/errors/not_found',
    layout: DefaultLayout,
    component: Error_400,
  },
  error_400_expired: {
    exact: true,
    title: 'Not_Found_Expired',
    path: '/errors/expired_gift',
    layout: GiftLayout,
    component: ExpiredGift,
  },
  emailAlreadyExists: {
    exact: true,
    title: 'Email-already-exists',
    path: '/errors/email_used',
    layout: DefaultLayout,
    component: EmailAlreadyExists,
  },
  registerYourAccountAgain: {
    exact: true,
    title: 'Register_your_account_again',
    path: '/errors/token_expired',
    layout: DefaultLayout,
    component: RegisterYourAccountAgain,
  },
  passwordTokenExpired: {
    exact: true,
    title: 'Password token expired',
    path: '/errors/password_token_expired',
    layout: DefaultLayout,
    component: PasswordTokenExpired,
  },
  gift: {
    exact: true,
    title: 'Gift',
    path: '/gifts/:id',
    layout: GiftLayout,
    component: Gift,
  },
  mypageHistory: {
    exact: true,
    title: 'My page history',
    path: '/mypage_history/:order_id',
    layout: DefaultLayout,
    component: MyPageHistory,
  },
  thanks: {
    exact: true,
    title: 'Thanks',
    path: '/thanks',
    layout: DefaultLayout,
    component: OrderSuccess,
  },
}

export const routes = Object.keys(routesObj).map(
  (item) => routesObj[item as keyof IRoutes]
)

export const pageNotFound = '/404'
