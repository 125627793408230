import { Steps, Popover, StepsProps } from 'antd'
import React from 'react'
import './index.scss'

interface IStep {
  value: string
  label: string
}

interface IBaseStep extends StepsProps {
  steps: IStep[]
  current: number
}

const { Step } = Steps
const customDot: StepsProps['progressDot'] = (dot) => (
  <Popover>{dot}</Popover>
)

const BaseStep = (props: IBaseStep) => {
  const {
    steps,
    current
  } = props
  return (
    <Steps className={`custom-step step-number__${steps.length}`} current={current} progressDot={customDot} {...props} >
      {steps.map(step => (
        <Step key={step.value} title={step.label} {...props}/>
      ))}
    </Steps>
  )
}

export default BaseStep
