import React, { useEffect, useState } from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import Breadcrumb from "../../../components/Breadcrumb";
import ButtonDefault from "../../../components/ButtonDefault";
import "./index.scss";
import { handleNext, handlePre } from "../../../redux/slices/stepSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {Row, Col, Checkbox, Spin, notification} from "antd";
import InputDefault from "../../../components/InputDefault";
import { AuthServices } from "../../../services/AuthServices";
import axios from "axios";
import { utils } from "../../../utils/utils";

const Mail = () => {
  const dispatch = useAppDispatch();
  const [mailInvalid, setMailInvalid] = useState<boolean>(false);
  const [changeInput, setChangeInput] = useState<boolean>(false)
  const [checkMail, setCheckMail] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailSend, setEmailSend] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dataPage = useAppSelector((state) => state.pageData);
  const character = useAppSelector((state) => state.character);
  const { orderAddress } = useAppSelector((state) => state.cart);
  const handleBack = () => {
    dispatch(handlePre());
  };
  const handleCheckMail = async () => {
    setLoading(true);
    try {
      await AuthServices.sendTestEmail({ email: email });
      let arrTemp = emailSend;
      arrTemp.push(email);
      setEmailSend(arrTemp);
      setLoading(false);
      notification.destroy();
      notification.open({
        message: "テストメールを送信しました。メール受信できているかご確認お願いします。",
        duration: 3.5,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setCheckMail(true);
  };
  const handleCompletion = async () => {
    const formData = new FormData();
    setLoading(true);
    formData.append(
      "authenticity_token",
      dataPage.data?.data?.data?.form_authenticity_token
    );
    formData.append("id", dataPage.data?.data?.data?.id);
    formData.append("character", JSON.stringify(character));
    formData.append("address", JSON.stringify(orderAddress));
    formData.append("selectedFile", character.infor.image.file);
    formData.append("email", email);
    try {
      const response = await axios({
        method: "post",
        url: "/gifts/gift_complete",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.status == 200) {
        console.log("success");
        setLoading(false);
        dispatch(handleNext());
      } else {
        window.onbeforeunload = () => {};
        window.location.href = `/errors/expired_gift`;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChange = (e: CheckboxChangeEvent) => {
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    if (regex.test(email)) {
      setChecked(e.target.checked);
    } else {
      setChecked(false);
    }
  };

  useEffect(() => {
    if (emailSend && mailInvalid) {
      if (emailSend.includes(email)) {
        setCheckMail(true);
        if (checked) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      } else {
        setChecked(false);
        setCheckMail(false);
      }
    }
  }, [email]);
  return (
    <>
      <Breadcrumb backButton={true} step={true} handleStep={handleBack}>
        メールアドレスの入力
      </Breadcrumb>
      <Spin spinning={loading}>
        <div className="gift-mail">
          <Row>
            <Col span={24}>
              <div className="label-mail">メールアドレス</div>
              <InputDefault
                className="input-email"
                onChange={(e) => {
                  setChangeInput(true)
                  const regex =
                    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
                  regex.test(e.target.value) && utils.validateEmail(e.target.value)
                    ? (setMailInvalid(true), setEmail(e.target.value))
                    : (setMailInvalid(false),
                      setChecked(false),
                      setCheckMail(false));
                }}
              />
              {!mailInvalid && changeInput ? (
                <div className="error-email__invalid error-invalid">メールアドレスは不正な値です。</div>
              ) : null}
              <ButtonDefault
                outline={false}
                classButton="full-big"
                disabled={!mailInvalid}
                className={mailInvalid ? null : "color-error"}
                htmlType="button"
                onClick={handleCheckMail}
              >
                テストメール送信
              </ButtonDefault>
              <Checkbox
                disabled={!mailInvalid || !checkMail}
                onChange={onChange}
                checked={mailInvalid && checked}
              >
                テストメール受信確認
              </Checkbox>
              <ButtonDefault
                outline={false}
                classButton="full-big"
                disabled={!mailInvalid || !checked}
                className={checked ? null : "color-error"}
                htmlType="button"
                onClick={handleCompletion}
              >
                <span> 完了 </span>
              </ButtonDefault>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default Mail;
