import React, { useEffect, useState } from "react";
import ButtonDefault from "../../ButtonDefault";
import nextIconButton from "../../../assets/images/navigate_next/navigate_next.png";
import momotaroDesignA from "../../../assets/images/order-gift-packaging/sample-momotaro-img_a.png";
import momotaroDesignB from "../../../assets/images/order-gift-packaging/sample-momotaro-img_b.png";
import issunboshiDesignA from "../../../assets/images/order-gift-packaging/sample-issunboshi-img_a.png";
import issunboshiDesignB from "../../../assets/images/order-gift-packaging/sample-issunboshi-img_b.png";
import "./index.scss";
import { Radio, RadioChangeEvent, Row } from "antd";
import Breadcrumb from "../../Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { handleNext, handlePre } from "../../../redux/slices/stepSlice";
import { giftPackagingAction } from "../../../redux/slices/cartSlice";
import { designFee, designNon, designPackaging, giftTitle } from "../../../../configuration";
import { utils } from "../../../utils/utils";

const GiftPackaging = ({ }) => {
  const dispatch = useAppDispatch();
  const giftOrder = useAppSelector((state) => state.cart);
  const arrGiftWrap = [];
  const arrGiftDesign = [];

  giftOrder.bookItems.map((item) => {
    arrGiftWrap.push(item.giftPackaging.chooseGift);
    arrGiftDesign.push(String(item.giftPackaging.typePackaging));
  });

  const [giftWrap, setGiftWrap] = useState(arrGiftWrap);
  const [giftDesign, setGiftDesign] = useState(arrGiftDesign);

  const selectGiftWrap = (e: RadioChangeEvent, index) => {
    const prev = giftWrap;
    prev[index] = e.target.value;
    setGiftWrap([...prev]);

    let data;
    if (giftWrap[index]) {
      data = {
        index: index,
        giftPackaging: {
          chooseGift: giftWrap[index],
          pricePackaging: designNon.price,
          typePackaging: String(designPackaging.designA.type),
        },
      };
    } else {
      data = {
        index: index,
        giftPackaging: {
          chooseGift: giftWrap[index],
          pricePackaging: designFee.price,
          typePackaging: String(designPackaging.designA.type),
        },
      };
    }
    dispatch(giftPackagingAction(data));
  };

  const selectGiftDesign = (e: RadioChangeEvent, index) => {
    const prev = giftDesign;
    prev[index] = e.target.value;
    setGiftDesign([...prev]);
    let data = {
      index: index,
      giftPackaging: {
        chooseGift: giftWrap[index],
        pricePackaging: designFee.price,
        typePackaging: String(giftDesign[index]),
      },
    };
    dispatch(giftPackagingAction(data));
  };

  const handleBack = () => {
    dispatch(handlePre());
  };
  const handleNextStep = () => {
    utils.actionScrollTo()
    dispatch(handleNext());
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    dispatch(handlePre());
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const designSrcA = (item) => {
    return (item?.book_id === 1 || item?.book_id === '1') ? momotaroDesignA : issunboshiDesignA;
  }

  const designSrcB = (item) => {
    return (item?.book_id === 1 || item?.book_id === '1') ? momotaroDesignB : issunboshiDesignB;
  }

  return (
    <>
      <div className="btn_breadcrumb">
        <Breadcrumb step={true} handleStep={handleBack}>
          ギフト包装を選択
        </Breadcrumb>
      </div>
      <div className="order_gift_packaging">
        <ButtonDefault
          classButton="full-big"
          outline={false}
          iconLeft={true}
          onClick={handleNextStep}
          htmlType="button"
        >
          次に進む
          <img src={nextIconButton} />
        </ButtonDefault>
        {giftOrder.bookItems.map((item, index) => (
          <div className="order_gift_packaging-border" key={index}>
            <div className="order_gift_padding" style={{ paddingBottom: !giftWrap[index] ? '22px' : '2px' }}>
              <div className="order_gift_title ">
                {!item?.is_gift ? (
                  <span style={{ fontSize: "20px", lineHeight: "26px" }}>{item?.book_name}<br/>（{item?.name}）</span>
                ) : (
                    <span style={{ fontSize: "20px", lineHeight: "26px" }}>{giftTitle}</span>
                )}
              </div>
              <div className="border_bottom"></div>
              <div className="gift_wrap-radio">
                <Radio.Group
                  onChange={(e) => selectGiftWrap(e, index)}
                  defaultValue={giftWrap[index]}
                >
                  <Row justify="space-between">
                    <Radio value={true}>無し</Radio>
                    {/* <span className="gift_wrap-price">¥0</span> */}
                  </Row>
                  <Row justify="space-between">
                    <Radio value={false}>ギフト包装する</Radio>
                    {/* <span className="gift_wrap-price">¥0</span> */}
                  </Row>
                </Radio.Group>
              </div>
              <div className="gift_wrap-design" style={ !giftWrap[index] ? {} : {display: 'none'} }>
                  <div className="design_img">
                    <Radio.Group
                      onChange={(e) => selectGiftDesign(e, index)}
                      defaultValue={giftDesign[index]}
                      style={{
                        display: "flex",
                        flexFlow: "row",
                      }}
                    >
                      <Row
                        style={{
                          flexFlow: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className="image-design"
                          src={designSrcA(item)}
                          alt="designA"
                        ></img>
                        <Radio value={designPackaging.designA.type}></Radio>
                      </Row>
                      <Row
                        style={{
                          flexFlow: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className="image-design left"
                          src={designSrcB(item)}
                          alt="designB"
                        ></img>
                        <Radio value={designPackaging.designB.type}></Radio>
                      </Row>
                    </Radio.Group>
                  </div>
                </div>
            </div>
          </div>
        ))}
        <div style={{ marginTop: "28px" }}>
          <ButtonDefault
            classButton="full-big"
            outline={false}
            iconLeft={true}
            onClick={handleNextStep}
            htmlType="button"
          >
            次に進む
            <img src={nextIconButton} />
          </ButtonDefault>
        </div>
      </div>
    </>
  );
};

export default GiftPackaging;
