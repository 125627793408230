import React, { useEffect, useLayoutEffect, useState } from "react";
import imageCartSuccess from "../../../assets/images/shopping_cart/img-check.svg";
import ButtonDefault from "../../../components/ButtonDefault";
import "./index.scss";
import { utils } from "../../../utils/utils";
import { MethodOption } from "../../../constants/methodOption";

interface IOrderSuccess {
  gift?: boolean;
}

const OrderSuccess = (props: IOrderSuccess) => {
  const orderId = utils.getStorage("order_id");
  const [methodRender, setMethodRender] = useState(utils.getStorage("method"))
  const [isGift, setIsGift] = useState(utils.getStorage('is_gift') === 'true');
  const handleClick = () => {
    window.location.href = `/mypage_history/${orderId}`;
  };
  useLayoutEffect(() => {
    utils.deleteStorage("order_id");
    utils.deleteStorage("is_gift");
    utils.deleteStorage("method");
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.href = '/'
  }

  useLayoutEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const renderContentWithGift = () => {
    if (isGift) {
      return (
        <div className="order-des">
          注文内容は以下から確認できます。
          <p>ギフトコードはメールにて確認できます</p>
        </div>
      )
    } else {
      return (
        <div className="order-des">
          注文内容、発送の状況は以下から
          <p>確認できます</p>
        </div>
      )
    }
  }

  return (
    <div className="order-success">
      <img
        className="image-order-success"
        src={imageCartSuccess}
        alt="cart-success"
      />
      <div className="order-thanks">
        ご注文いただき
        <p>ありがとうございます。</p>
      </div>
      {
        methodRender === MethodOption.STORE.value ? (
          <div className="order-des">
            コンビニ支払い用のメールを送信しました。
            <p>詳細はメールをご確認ください。 </p>
          </div>
        ) : (
          renderContentWithGift()
        )
      }
      <ButtonDefault
        classButton="full-small full-small__border"
        outline={false}
        onClick={handleClick}
        htmlType="button"
      >
        注文内容へ
      </ButtonDefault>
    </div>
  );
};

export default OrderSuccess;
