import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { itemType, itemsType, bookType, orderAddressType, orderMethodType, giftPackagingPayload } from '../../types/bookType'
import { utils } from '../../utils/utils'
const itemsCart = JSON.parse(utils.getStorage('items'))

interface SelectedBookType {
  selectedBook: bookType[]
  bookItems: itemType[]
  cartTotalPrice: number
  cartTotalItem: number
  orderAddress: orderAddressType
  orderMethod: orderMethodType
}
interface SubType {
  itemsId: number
  itemId: number
}

const initialState: SelectedBookType = itemsCart || {}

const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    // action change cart page
    handleAddItem: (state, action: PayloadAction<SelectedBookType>) => {
      state.selectedBook = action.payload.selectedBook
      state.bookItems = action.payload.bookItems
      state.cartTotalPrice = action.payload.cartTotalPrice
      state.cartTotalItem = action.payload.cartTotalItem
    },
    handleFinish: (state) => {
      state.selectedBook = []
      state.bookItems = []
      state.cartTotalPrice = 0
      state.cartTotalItem = 0
      state.orderAddress = {}
      state.orderMethod = {}
    },
    selectBook: (state, action: PayloadAction<bookType>) => {
      state.selectedBook[action.payload.id] = action.payload
    },
    increaseAction: (state, action: PayloadAction<itemType>) => {
      if (state.selectedBook[action.payload.indexArr].amount < 99) {
        state.selectedBook[action.payload.indexArr].amount += 1
        state.cartTotalPrice = state.cartTotalPrice + state.selectedBook[action.payload.indexArr]?.price
        const index = state.bookItems.findIndex(item => {
          return item.index === action.payload.index
        })
        state.bookItems.splice(index, 0, action.payload);
      } else {
        return
      }
    },
    decreaseAction: (state, action: PayloadAction<SubType>) => {
      if (state.selectedBook[action.payload.itemsId].amount >= 2) {
        state.selectedBook[action.payload.itemsId].amount -= 1
        state.cartTotalPrice = state.cartTotalPrice - state.selectedBook[action.payload.itemsId].price
        const index = state.bookItems.findIndex(item => {
          return item.index === action.payload.itemId
        })
        state.bookItems.splice(index, 1)
      } else {
        return
      }
    },
    removeAction: (state, action: PayloadAction<number>) => {
      state.cartTotalPrice = state.cartTotalPrice - state.selectedBook[action.payload]?.price * state.selectedBook[action.payload]?.amount || 0
      state.cartTotalItem = state.cartTotalItem - 1 || 0
      if (state.selectedBook.length === 1) {
        state.selectedBook = []
        state.bookItems = []
      }
      state.bookItems = state.bookItems.filter(item => (item.index !== state.selectedBook[action.payload].id))
      state.selectedBook.splice(action.payload, 1)
    },
    // action order
    giftPackagingAction: (state, action: PayloadAction<giftPackagingPayload>) => {
      state.bookItems[action.payload.index].giftPackaging = action.payload.giftPackaging
    },
    addressAction: (state, action: PayloadAction<orderAddressType>) => {
      state.orderAddress = action.payload
    },
    methodAction: (state, action: PayloadAction<orderMethodType>) => {
      state.orderMethod = action.payload
    },
  }
})

export const { handleAddItem, selectBook, increaseAction, decreaseAction, removeAction, giftPackagingAction, addressAction, methodAction, handleFinish } = cartSlice.actions
export default cartSlice.reducer
