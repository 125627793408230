import React, { useEffect } from 'react'
import iconSend from '../../../../assets/images/contact/img_send.svg'
import { useAppDispatch } from '../../../../hooks';
import { utils } from '../../../../utils/utils';

import './index.scss'

const Contact = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    utils.actionScrollTo()
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);

    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.href = '/'
  }

  return (
    <div
      className="contact_send"
    >
      <div className="image" style={{ paddingTop: '37px' }}>
        <img className="img-send" src={iconSend} alt="img-send" />
      </div>
      <div className="title-img-send">お問い合わせを送信しました</div>
    </div>
  )
}

export default Contact;
