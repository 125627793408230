import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Input, Spin } from "antd";
import Breadcrumb from "../../Breadcrumb";
import ButtonDefault from "../../ButtonDefault";
import navigateNext from "../../../assets/images/navigate_next/navigate_next_black_24dp.svg";
import { zipCloudServices } from "../../../services/zipCloudServices";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { handleNext, handlePre } from "../../../redux/slices/stepSlice";
import { addressAction } from "../../../redux/slices/cartSlice";
import { CaretDownOutlined } from "@ant-design/icons";
import "./index.scss";
import SubmitError from "../../SubmitError";
import { utils } from "../../../utils/utils";

interface IAddress {
  handleNextPage?: any
}

const Address = (props: IAddress) => {
  const { handleNextPage  } = props;
  const [form] = Form.useForm();
  const dataPage = useAppSelector((state) => state.pageData);
  const { orderAddress } = useAppSelector((state) => state.cart);
  const [messageError, setMessageError] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    dispatch(handlePre())
  }
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (orderAddress?.name) {
      form.setFieldsValue({ ...orderAddress });
    } else if (Object.keys(user).length) {
      form.setFieldsValue({
        name: user.name,
        furigana: user.furigana,
      });
    }
  }, []);

  const prefectures = dataPage?.data?.data?.prefectures;
  const user = dataPage?.data?.data?.user || {};
  const dispatch = useAppDispatch();
  const handelInputZipCode = async (value) => {
    setLoading(true)
    const { data } = await zipCloudServices.getAddress(value);
    if (data.results) {
      const { address1, address2, address3 } = data.results[0];
      if (address1) form.setFieldsValue({ address1 });
      if (address2 && address3) form.setFieldsValue({ address2: address2 + address3 });
    }
    setLoading(false)
  };
  const handlePreStep = () => {
    dispatch(handlePre());
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
    utils.actionScrollTo()
  };

  const checkName = async () => {
    const name = (form.getFieldValue("name") || "").trim();
    if (name.length === 0) {
      return await Promise.reject("名前を入力してください。");
    }
    if (name.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };

  const checkFurigana = async () => {
    const furigana = (form.getFieldValue("furigana") || "").trim();
    const regex = /^[\u3040-\u309F\u3000 ]*$/g;
    if (!regex.test(furigana || furigana.length === 0)) {
      return await Promise.reject("ふりがなを入力してください。");
    }
    if (furigana.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };

  const normalizeNumber = (value) => {
    const isInteger = /^[0-9 ]+$/;
    if (!value) {
      return value;
    }
    let onlyNums = value.replace(/[^\d]/g, "");
    if (isInteger.test(value)) {
      onlyNums = value.replace(/ /g, "").substring(0, 15);
    } else {
      onlyNums = value.replace(/\D/g, "").replace(/ /g, "").substring(0, 15);
    }
    let numsWithDash = `${onlyNums.substring(0, 3)} ${onlyNums.substring(
      3,
      7
    )} ${onlyNums.substring(7)}`
      .trim()
      .replace(/ /g, "-");

    return numsWithDash;
  };

  const normalizeNumberPhone = (value) => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    return onlyNums;
  };

  const checkZipCode = async () => {
    const zipcode = (form.getFieldValue("zipcode") || "")
      .trim()
      .replace(/-/, "");
    if (!zipcode || zipcode.length === 0) {
      return await Promise.reject("郵便番号を入力してください。");
    }
    if (zipcode.length > 7) {
      return await Promise.reject("最大は7数字です。");
    }

    return await Promise.resolve();
  };

  const checkAddress1 = async () => {
    const address1 = (form.getFieldValue("address1") || "").trim();
    if (address1.length === 0) {
      return await Promise.reject(" 都道府県を入力してください。");
    }
    if (address1.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };
  const checkAddress2 = async () => {
    const address2 = (form.getFieldValue("address2") || "").trim();
    if (address2.length === 0) {
      return await Promise.reject("市区町村名を入力してください。");
    }
    if (address2.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };
  const checkAddress3 = async () => {
    const address3 = (form.getFieldValue("address3") || "").trim();
    if (address3.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };

  const checkPhone = async () => {
    const phone = (form.getFieldValue("phone") || "").trim();
    if (!phone || phone.length === 0) {
      return await Promise.reject(" 電話番号を入力してください。");
    }
    if (!(phone.length == 10 || phone.length == 11)) {
      return await Promise.reject("10または11の数字のみを入力してください。");
    }
  };

  useEffect(() => {
    const selectTags = document.getElementsByClassName('ant-select-in-form-item')
    if (open) {
      document.body.classList.add('lock-scroll')
      selectTags[0].getElementsByTagName('input')[0].focus()
    }
    else {
      document.body.classList.remove('lock-scroll')
      selectTags[0].getElementsByTagName('input')[0].blur()
    }
  }, [open])

  return (
    <>
      <div className="delivery-breadcrumb">
        <Breadcrumb step={true} backButton={true} handleStep={handlePreStep}>
          配送先
        </Breadcrumb>
      </div>
      {isError ? (
        <SubmitError
          description={messageError}
          type="error"
          closable
          style={{ whiteSpace: "break-spaces" }}
        />
      ) : null}
      <div>
        <Spin spinning={loading}>
          <Form
            scrollToFirstError
            form={form}
            onFinishFailed={onFinishFailed}
            onFinish={(values) => handleNextPage(values)}
            className="form-delivery"
          >
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  label="名前"
                  name="name"
                  rules={[{ validator: checkName }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="ふりがな"
                  name="furigana"
                  rules={[{ validator: checkFurigana }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="郵便番号"
                  name="zipcode"
                  normalize={normalizeNumber}
                  rules={[{ validator: checkZipCode }]}
                >
                  <Input onBlur={(e) => handelInputZipCode(e.target.value)} />
                </Form.Item>

                <Form.Item
                  label="都道府県"
                  name="address1"
                  rules={[{ validator: checkAddress1 }]}
                >
                  <Select open={open}
                    onBlur={() => {
                      setOpen(false)
                      document.body.classList.remove('lock-scroll')
                    }}
                    onClick={() => setOpen(!open)}
                    suffixIcon={<CaretDownOutlined onClick={() => setOpen(!open)}/>}
                    onDropdownVisibleChange={ (open) => {
                      if (open)
                        document.body.classList.add('lock-scroll')
                      else
                        document.body.classList.remove('lock-scroll')
                    } }
                    onSelect={() => setTimeout(() => {
                      const selectTags = document.getElementsByClassName('ant-select-in-form-item')
                      for (let i = 0; i < selectTags.length; i++) {
                        selectTags[i].getElementsByTagName('input')[0].blur()
                      }
                    }, 100)}
                    dropdownAlign={{
                      offset: [0, 4],
                      overflow: {
                        adjustX: 0,
                        adjustY: 0,
                      },
                    }}
                    onPopupScroll={() => document.body.classList.add('lock-scroll')}
                  >
                    {prefectures &&
                      prefectures.map((option, index) => {
                        return (
                          <Select.Option key={index} value={option}>
                            {option}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="住所"
                  name="address2"
                  rules={[{ validator: checkAddress2 }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="建物名・部屋番号"
                  name="address3"
                  rules={[{ validator: checkAddress3 }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="電話番号"
                  name="phone"
                  normalize={normalizeNumberPhone}
                  rules={[{ validator: checkPhone }]}
                >
                  <Input />
                </Form.Item>

                <div className="btn-preview">
                  <ButtonDefault
                    outline={false}
                    iconLeft={true}
                    classButton="full-big"
                    htmlType="submit"
                  >
                    次に進む
                    <img src={navigateNext} />
                  </ButtonDefault>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default Address;
