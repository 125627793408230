import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDataPage } from "../../../redux/slices/pageDataSlice";
import { Button, Form, notification, Spin } from "antd";
import { AuthServices } from "../../../services/AuthServices";
import InputDefault from "../../../components/InputDefault";
import SubmitError from "../../../components/SubmitError";
import "./index.scss";
import { utils } from "../../../utils/utils";

const LoginInfo = (data) => {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [form] = Form.useForm();
  const dataPage = useAppSelector((state) => state.pageData);
  const currentUser = JSON.parse(utils.getStorage("current_user"));
  const openNotificationWithIcon = (type, message) => {
    notification.destroy();
    notification.open({
      message: "更新しました。",
      description: message,
    });
  };
  const checkEmail = async () => {
    const inputEnter = (form.getFieldValue("email") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("メールアドレスを入力してください。");
    }
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (!regex.test(inputEnter) || !utils.validateEmail(inputEnter)) {
      return await Promise.reject("メールアドレスは不正な値です。");
    }

    return await Promise.resolve();
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDataPage(data));
  }, [dispatch]);

  const onFinish = async (values: any) => {
    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      email: values.email.trim(),
      password: values?.password || "",
      update_form: "login_info",
    };
    setLoading(true);

    try {
      const res = await AuthServices.updateInfo(valuesSend);
      const email = (form.getFieldValue("email") || "").trim();
      form.setFieldValue("email", email);
      if (res.status == 204) {
        setIsError(false);
        openNotificationWithIcon("success", res.data.message);
      } else {
        setIsError(true);
        setMessageError(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
  };

  const checkPassword = async () => {
    const inputEnter = form.getFieldValue("password") || "";

    if (inputEnter.length === 0) {
      return await Promise.reject("パスワードを入力してください。");
    }
    const regex = /^[A-Za-z0-9]+$/i;
    if (!regex.test(inputEnter)) {
      return await Promise.reject("英数字を入力してください。");
    }
    if (inputEnter.length < 8) {
      return await Promise.reject(
        "パスワードは8文字以上にする必要があります"
      );
    }

    return await Promise.resolve();
  };

  const returnToMypage = () => {
    window.location.href = '/my_page'
  }

  return (
    <Spin spinning={loading} wrapperClassName='profile-spin'>
      <div className="breadcrumb_info">
        <Breadcrumb backButton={true} handleStep={returnToMypage}>ログイン情報</Breadcrumb>
      </div>
      <div className="form_info login-info">
        <Form
          name="basic"
          form={form}
          initialValues={{ email: currentUser?.email }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {isError ? (
            <SubmitError
              description={messageError}
              type="error"
              closable
              style={{ whiteSpace: "break-spaces" }}
            />
          ) : null}
          <Form.Item
            label="メールアドレス"
            name="email"
            className="label_form_log_info"
            rules={[{ validator: checkEmail }]}
          >
            <InputDefault />
          </Form.Item>

          <Form.Item
            label="パスワード"
            name="password"
            className="label_form_log_info"
            rules={[{ validator: checkPassword }]}
          >
            <InputDefault className="input_password" type="password" />
          </Form.Item>

          <Form.Item>
            <div className="btn_update">
              <Button type="primary" htmlType="submit">
                変更する
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default LoginInfo;
