import React, { useEffect, useState } from "react";
import { Form, Button, notification } from "antd";
import ButtonDefault from "../../components/ButtonDefault";
import { routesObj } from "../../routes/routes";
import InputDefault from "../../components/InputDefault";
import { AuthServices } from "../../services/AuthServices";
import "./index.scss";
import { useAppSelector } from "../../hooks";
import Breadcrumb from "../../components/Breadcrumb";
import SubmitError from "../../components/SubmitError";
import imgSend from "../../assets/images/img_send/img_send.svg";
import { utils } from "../../utils/utils";

const NewPassword = () => {
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dataPage = useAppSelector((state) => state.pageData);
  const [form] = Form.useForm();
  const checkEmail = async () => {
    const inputEnter = (form.getFieldValue("email") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("メールアドレスを入力してください。");
    }
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (!regex.test(inputEnter) || !utils.validateEmail(inputEnter)) {
      return await Promise.reject("メールアドレスは不正な値です。");
    }

    return await Promise.resolve();
  };
  const onFinish = async (values: any) => {
    if (isSubmitted) return;
    setIsSubmitted(true);

    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      email: values.email.trim(),
    };

    try {
      const res = await AuthServices.newPasswordUser(valuesSend);
      if (res.data.status == 200) {
        setIsSent(true);
      } else {
        setIsError(true);
        setIsSubmitted(false);
        setMessageError("メールアドレスが存在しません。");
      }
    } catch (error) {
      console.log(error);
      setIsSubmitted(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setIsSubmitted(false);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
  };

  return (
    <>
      {isSent ? (
        <div
          className="form-NewPassword"
          style={{
            textAlign: "center",
            marginBottom: "max(1px, 100vh - 507px)",
          }}
        >
          <img src={imgSend} style={{ paddingTop: "37px" }} />
          <h2
            style={{
              paddingTop: "20px",
              fontFamily: "Noto_Sans_Bold",
              fontWeight: "700",
              fontSize: "20px",
            }}
          >
            メールを送信しました
          </h2>
        </div>
      ) : (
        <>
          <div className="breadcrumb_info">
            <Breadcrumb backButton={true}>パスワードをお忘れの方</Breadcrumb>
          </div>
          <div
            className="form-NewPassword"
            style={{ marginBottom: "max(1px, 100vh - 640px)" }}
          >
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {isError ? (
                <SubmitError
                  description={messageError}
                  type="error"
                  closable
                  style={{ whiteSpace: "break-spaces" }}
                />
              ) : null}
              <h2
                style={{
                  width: "100%",
                  fontSize: "18px",
                  fontFamily: "Noto_Sans_Regular",
                  color: "#22304D",
                  marginBottom: "28px",
                }}
              >
                パスワードをお忘れの方はメールアドレスを入力してください
              </h2>
              <Form.Item
                label="メールアドレス"
                name="email"
                className="label-form"
                rules={[{ validator: checkEmail }]}
              >
                <InputDefault />
              </Form.Item>

              <Form.Item className="btn-reset-password">
                <ButtonDefault
                  classButton="full-big"
                  outline={false}
                  htmlType="submit"
                >
                  送信{" "}
                </ButtonDefault>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default NewPassword;
