import { Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import InputDefault from '../../../../components/InputDefault'
import SubmitError from '../../../../components/SubmitError'
import ButtonDefault from '../../../../components/ButtonDefault'
import './index.scss'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { handleNext } from '../../../../redux/slices/stepSlice'
import { handleSaveContact } from '../../../../redux/slices/contactSlice'
import { utils } from '../../../../utils/utils'

const NewContact = () => {
  const dispatch = useAppDispatch();
  const contact = useAppSelector((state) => state.contact)
  const [form] = Form.useForm()
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState("");

  const handleNextStep = (values: any) => {
    setIsError(false);
    dispatch(handleNext());
    dispatch(handleSaveContact(values));
  }

  const onFinishFailed = (errorInfo: any) => {
    utils.actionScrollTo()
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください "
    );
  };

  const checkName = async () => {
    const name = (form.getFieldValue('name') || '').trim()
    if (name.length === 0) {
      return await Promise.reject('名前を入力してください')
    }
    if (name.length > 255) {
      return await Promise.reject('最大は255文字になります')
    }

    return await Promise.resolve()
  };

  const checkEmail = async () => {
    const inputEnter = (form.getFieldValue("email") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("正しいメールアドレスを入力してください");
    }
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (!regex.test(inputEnter) || !utils.validateEmail(inputEnter)) {
      return await Promise.reject("メールアドレスは不正な値です。");
    }
    return await Promise.resolve();
  };

  const checkFurigana = async () => {
    const inputEnter = (form.getFieldValue("furigana") || "").trim();
    const regex = /^[\u3040-\u309F\u3000 ]*$/g;
    if (!regex.test(inputEnter)) {
      return await Promise.reject("ふりがなを入力してください");
    }
    if (inputEnter.length > 255) {
      return await Promise.reject("最大は255文字になります");
    }

    return await Promise.resolve();
  };

  const normalizeNumberPhone = (value) => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    return onlyNums;
  };

  const checkPhone = async () => {
    const phone = (form.getFieldValue("phone") || "").trim();
    if (phone && !(phone.length == 10 || phone.length == 11)) {
      return await Promise.reject("10または11の数字のみを入力してください");
    }
  };

  const inputTextarea = (e) => {
    const MAX_LEN = 1000;
    const value = e.target.value
    let maxValue = 0
    let totalByte = 0

    for (let i = 0; i < value.length; i++) {

      const code = value.charCodeAt(i);
      if ((code >= 0x0020 && code <= 0x1FFF) || (code >= 0xFF61 && code <= 0xFF9F)) {
        totalByte += 1;
      } else if ((code >= 0x2000 && code <= 0xFF60) || (code >= 0xFFA0)) {
        totalByte += 2;
      }
      if (totalByte === MAX_LEN) {
        maxValue = i + 1
      }
    }
    let formatValue = value

    if (totalByte > MAX_LEN) {
      formatValue = formatValue.substring(0, maxValue);
      form.setFieldsValue({ content: formatValue })
    }
  }

  return (
    <>
      <Breadcrumb backButton={false}>お問い合わせ</Breadcrumb>
      <div className='contact'>
        <Form
          name="basic"
          form={form}
          onFinish={handleNextStep}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            name: contact?.newContact?.name,
            furigana: contact?.newContact?.furigana,
            email: contact?.newContact?.email,
            phone: contact?.newContact?.phone,
            orderNumber: contact?.newContact?.orderNumber,
            slipNumber: contact?.newContact?.slipNumber,
            content: contact?.newContact?.content
          }}
        >
          {isError && (
            <SubmitError
              description={messageError}
              type="error"
              closable
              style={{ whiteSpace: "break-spaces" }}
            />
          )}
          <Form.Item
            label="名前"
            name="name"
            className="label_form required"
            rules={[{ validator: checkName }]}
          >
            <InputDefault className="input" />
          </Form.Item>

          <Form.Item
            label="ふりがな"
            name="furigana"
            className="label_form"
            rules={[{ validator: checkFurigana }]}
          >
            <InputDefault />
          </Form.Item>

          <Form.Item
            label="電話番号"
            name="phone"
            normalize={normalizeNumberPhone}
            rules={[{ validator: checkPhone }]}
            className="label_form"
          >
            <InputDefault />
          </Form.Item>

          <Form.Item
            label="メールアドレス"
            name="email"
            className="label_form required"
            rules={[{ validator: checkEmail }]}
          >
            <InputDefault />
          </Form.Item>

          <Form.Item
            label="注文番号"
            name="orderNumber"
            normalize={normalizeNumberPhone}
            className="label_form"
          >
            <InputDefault />
          </Form.Item>
          <Form.Item
            label="伝票番号"
            name="slipNumber"
            className="label_form"
          >
            <InputDefault />
          </Form.Item>
          <Form.Item
            label="お問い合わせ内容"
            name="content"
            className="label_form required"
            rules={[{ required: true, message: 'お問い合わせ内容を入力してください' }]}
          >
            <TextArea onChange={inputTextarea} />
          </Form.Item>

          <Form.Item>
            <div className="btn_summit">
              <ButtonDefault
                classButton="full-big"
                outline={false}
                iconLeft={true}
                htmlType="submit"
              >
                確認画面へ
              </ButtonDefault>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default NewContact;
